import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index'; // Импортируйте ваше хранилище



const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/Home.vue")
  },


  {
    path: "/city",
    name: "City",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/City.vue")
  },


  {
    path: "/car",
    name: "Car",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/Car.vue")
  },


  {
    path: "/trailers",
    name: "Trailers",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/Trailers.vue")
  },


  {
    path: "/drivers",
    name: "Drivers",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/Drivers.vue")
  },

  {
    path: "/directory",
    name: "Directory",
    meta: { layout: "other", auth: true, requiresAuth: true },
    component: () => import("../views/Directory.vue")
  },



  {
    path: '/settings',
    name: 'Settings',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/Settings.vue')
  },




  {
    path: '/statistics',
    name: 'Statistics',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/Statistics.vue')
  },




  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'login' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    meta: { layout: 'login' },
    component: () => import('../views/PasswordRecovery.vue')
  },



  {
    path: '/chat',
    name: 'Chat',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/chat/ChatList.vue')
  },
  {
    path: '/chat/:id',
    name: 'ChatMessage',
    meta: { layout: 'other', requiresAuth: true, url: '/chat' },
    component: () => import('../views/chat/Chat.vue')
  },
  {
    path: '/chat/participants',
    name: 'ChatParticipants',
    meta: { layout: 'other', requiresAuth: true, url: '/chat' },
    component: () => import('../views/chat/ChatParticipants.vue')
  },



  {
    path: '/orders',
    name: 'Agreements',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/agreements/Agreements.vue')
  }, {
    path: '/orders/new',
    name: 'AgreementsCreate',
    meta: { layout: 'other', requiresAuth: true, url: '/orders' },
    component: () => import('../views/agreements/AgreementsCreate.vue')
  }, {
    path: '/orders/:id',
    name: 'AgreementsCreateId',
    meta: { layout: 'other', requiresAuth: true, url: '/orders' },
    component: () => import('../views/agreements/AgreementsCreate.vue')
  },


  {
    path: '/calendar',
    name: 'Calendar',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/Calendar.vue')
  },


  {
    path: '/tasks',
    name: 'Tasks',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/tasks/TasksList.vue')
  },
  {
    path: '/tasks/new',
    name: 'TaskCard',
    meta: { layout: 'other', requiresAuth: true, url: '/tasks' },
    component: () => import('../views/tasks/TaskCard.vue')
  }, {
    path: '/tasks/:id',
    name: 'TaskCardId',
    meta: { layout: 'other', requiresAuth: true, url: '/tasks' },
    component: () => import('../views/tasks/TaskCard.vue')
  },


  {
    path: '/clients',
    name: 'Clients',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/clients/Clients.vue')
  }, {
    path: '/clients/new',
    name: 'ClientsCreate',
    meta: { layout: 'other', requiresAuth: true, url: '/clients' },
    component: () => import('../views/clients/ClientsCreate.vue')
  }, {
    path: '/clients/:id',
    name: 'ClientsCreateId',
    meta: { layout: 'other', requiresAuth: true, url: '/clients' },
    component: () => import('../views/clients/ClientsCreate.vue')
  },



  {
    path: '/leads',
    name: 'Leads',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/leads/Leads.vue')
  }, {
    path: '/leads/new',
    name: 'LeadsCreate',
    meta: { layout: 'other', requiresAuth: true, url: '/leads' },
    component: () => import('../views/leads/LeadsCreate.vue')
  }, {
    path: '/leads/:id',
    name: 'LeadsCreateId',
    meta: { layout: 'other', requiresAuth: true, url: '/leads' },
    component: () => import('../views/leads/LeadsCreate.vue')
  },



  {
    path: '/contractors',
    name: 'Contractors',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/contractors/Contractors.vue')
  }, {
    path: '/contractors/new',
    name: 'ContractorsCreate',
    meta: { layout: 'other', requiresAuth: true, url: '/contractors' },
    component: () => import('../views/contractors/ContractorsCreate.vue')
  }, {
    path: '/contractors/:id',
    name: 'ContractorsCreateId',
    meta: { layout: 'other', requiresAuth: true, url: '/contractors' },
    component: () => import('../views/contractors/ContractorsCreate.vue')
  },



  {
    path: '/quotes',
    name: 'Quotations',
    meta: { layout: 'other', requiresAuth: true },
    component: () => import('../views/quotations/Quotations.vue')
  }, {
    path: '/quotes/new',
    name: 'QuotationsCreate',
    meta: { layout: 'other', requiresAuth: true, url: '/quotes' },
    component: () => import('../views/quotations/QuotationsCreate.vue')
  }, {
    path: '/quotes/:id',
    name: 'QuotationsCreateId',
    meta: { layout: 'other', requiresAuth: true, url: '/quotes' },
    component: () => import('../views/quotations/QuotationsCreate.vue')
  },

]



// Создайте маршрутизатор
const router = createRouter({
  history: createWebHistory(), // Используйте HTML5 History API
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }; // Прокрутка к верхней части страницы при переходе
  },
});

// Защитите маршруты с помощью beforeEach
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next(); // Пользователь аутентифицирован, продолжайте
    } else {
      next('/login'); // Перенаправьте на страницу входа
    }
  } else {
    next(); // Прямой доступ к маршруту без аутентификации
  }
});

export default router;
