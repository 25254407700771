<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2 v-if="mod == 'car'">{{$store.state.langText.cars}}</h2>
        <h2 v-if="mod == 'org'">{{$store.state.langText.organizations}}</h2>
        <h2 v-if="mod == 'line'">{{$store.state.langText.lines}}</h2>
        <h2 v-if="mod == 'courtAction'">{{$store.state.langText.ship_calls}}</h2>
        <h2 v-if="mod == 'carModels'">{{$store.state.langText.brands}}</h2>
        <h2 v-if="mod == 'carManufacturers'">{{$store.state.langText.models}}</h2>
        <h2 v-if="mod == 'servicesName'">{{$store.state.langText.name}}</h2>
        <h2 v-if="mod == 'incomeNDS'">{{$store.state.langText.income_VAT}}</h2>
        <h2 v-if="mod == 'paymentTypes'">{{$store.state.langText.payment_type_income}}</h2>
        <h2 v-if="mod == 'conditions'">{{$store.state.langText.incoterms}}</h2>
        <h2 v-if="mod == 'cargo'">{{$store.state.langText.cargoes}}</h2>
        <h2 v-if="mod == 'route'">{{$store.state.langText.routes}}</h2>
        <Search v-if="mod == 'car'" :filterNone="'none'" @inputchange="searchBtn"/>
        <Search v-if="mod == 'courtAction'" :filterNone="'none'" @inputchange="searchBtn"/>
        <Search v-if="mod == 'cargo'" :filterNone="'none'" @inputchange="searchBtn"/>
        <Search v-if="mod == 'route'" :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">{{$store.state.langText.nothing_found}}</p>



        <ul class="pop-up__list">
          <li
            v-for="(item, index) in lists"
            :key="index"
            :data-id="item.id"
            @click.prevent="$emit('close', {id: item.id, name: item.name, item})"
          >
            <div class="pop-up__list__row">
              {{item.name}}
            </div>
            <div class="decor-line"></div>
          </li>
        </ul>



        <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
      </div>
    </div>
  </transition>
</template>


<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"

export default {
  props: ['popup', 'mod'],

  data:() => ({
    lists: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    listNot: false
    
  }),
  async created() {
    //base
    if(this.mod == 'car'){
      var list = await this.$store.dispatch('postFiltersCar', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'org'){
      var list = await this.$store.dispatch('getOrg', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'line'){
      var list = await this.$store.dispatch('getlines', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'courtAction'){
      var list = await this.$store.dispatch('courtAction', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'carModels'){
      var list = await this.$store.dispatch('carModels', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'carManufacturers'){
      var list = await this.$store.dispatch('carManufacturers', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'servicesName'){
      var list = await this.$store.dispatch('servicesName', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'incomeNDS'){
      var list = await this.$store.dispatch('incomeNDS', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'paymentTypes'){
      var list = await this.$store.dispatch('paymentTypes', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'conditions'){
      var list = await this.$store.dispatch('conditions', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'cargo'){
      var list = await this.$store.dispatch('cargo', {params: this.params, data: this.arrFilters});
    }
    if(this.mod == 'route'){
      var list = await this.$store.dispatch('route', {params: this.params, data: this.arrFilters});
    }

    this.lists = list.data.list;
    this.quantityList = list.data.total_num
  },
  methods: {

    //search
    async searchBtn(data) {
      if(this.mod == 'courtAction'){
        var arrFilters = await this.$store.dispatch('courtAction', {params: this.params, data: data.search});
      }
      if(this.mod == 'car'){
        var arrFilters = await this.$store.dispatch('postFiltersCar', {params: this.params, data: data.search})
      }
      if(this.mod == 'cargo'){
        var arrFilters = await this.$store.dispatch('cargo', {params: this.params, data: data.search});
      }
      if(this.mod == 'route'){
        var arrFilters = await this.$store.dispatch('route', {params: this.params, data: data.search});
      }
      this.lists = arrFilters.data.list
      this.arrFilters = data.search
      this.quantityList = arrFilters.data.total_num
      if(arrFilters.data.total_num == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },

    
    async pagination(data){
      this.params.start = data.start
      if(this.mod == 'car'){
        var list = await this.$store.dispatch('postFiltersCar', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'org'){
        var list = await this.$store.dispatch('getOrg', this.params);
      }
      if(this.mod == 'line'){
        var list = await this.$store.dispatch('getlines', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'courtAction'){
        var list = await this.$store.dispatch('courtAction', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'carModels'){
        var list = await this.$store.dispatch('carModels', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'carManufacturers'){
        var list = await this.$store.dispatch('carManufacturers', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'servicesName'){
        var list = await this.$store.dispatch('servicesName', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'incomeNDS'){
        var list = await this.$store.dispatch('incomeNDS', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'paymentTypes'){
        var list = await this.$store.dispatch('paymentTypes', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'conditions'){
      var list = await this.$store.dispatch('conditions', {params: this.params, data: this.arrFilters});
    }
      if(this.mod == 'cargo'){
        var list = await this.$store.dispatch('cargo', {params: this.params, data: this.arrFilters});
      }
      if(this.mod == 'route'){
        var list = await this.$store.dispatch('route', {params: this.params, data: this.arrFilters});
      }
      
      this.lists = list.data.list;
    }
  },

  beforeUnmount() {
    this.lists = []
    this.quantityList = ''
    //pagination
    this.params.start = 0
    this.params.limit = 20
    this.arrFilters = ''
    this.listNot = false
  },

  components:{
    Pagination, Search
  }
  
}
</script>