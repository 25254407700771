import axios from 'axios'

export default {
  actions: {

    async getLeads({ commit }, { params: { start, limit }, data }) {
      try {
        return await axios
          ('/api/leads/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: { filters: data }
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },



    async getLeadsId({ commit }, id) {
      var result;
      try {
        return await axios
          .get("/api/leads/get/" + id + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },



    async getLeadsStatus({ commit }) {
      var result;
      try {
        return await axios
          .get("/api/leads_statuses/list/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },


    async updateLeads({ commit }, data) {
      try {
        return await axios
          ('/api/leads/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },


    async addLeads({ commit }, data) {
      try {
        return await axios
          ('/api/leads/add/web/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },




    async getLeadsHistory({ commit }, id) {
      var result;
      try {
        return await axios
          .get("/api/clients/get_contact_history/" + id + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getLeadsHistoryChat({ commit }, { id, params: { start, limit } }) {
      var result;
      try {
        return await axios
          .get("/api/clients/get_contact_history/" + id + "/?start=" + start + "&num=" + limit + '&object=fact', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },




    async addContacts_hist({ commit }, data) {
      try {
        return await axios
          ('/api/contacts_hist/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },


    async updateContacts_hist({ commit }, data) {
      try {
        return await axios
          ('/api/contacts_hist/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
}