<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2>{{$store.state.langText.legal_entity_name}}</h2>
        <Search :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">{{$store.state.langText.nothing_found}}</p>
        <ul class="pop-up__list">
          <li
            v-for="(person, index) in legalPersons"
            :key="index"
            :data-id="person.id"
            @click.prevent="$emit('close', {id: person.id, name: person.name})"
          >
            <div class="pop-up__list__row">
              {{person.name}}
            </div>
            <div class="decor-line"></div>
          </li>
        </ul>
        <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
      </div>
    </div>
  </transition>
</template>


<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }

</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"

export default {
  props: ['popup', 'data_legal_persons'],

  data:() => ({
    legalPersons: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    searchData: '',
    filtersData: '',
    listNot: false
    
  }),
  async mounted() {
    //base
    if(this.data_legal_persons.idContractor){
      this.filtersData = 'f[contractor_id]='+this.data_legal_persons.idContractor
      this.arrFilters = this.filtersData
    }
    const legalPerson = await this.$store.dispatch('postFiltersLegalPerson', {params: this.params, data: this.arrFilters})
    this.legalPersons = legalPerson.data.list;
    this.quantityList = legalPerson.data.total_num
  },
  methods: {


    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData
      var arrFilters = await this.$store.dispatch('postFiltersLegalPerson', {params: this.params, data: this.arrFilters})
      this.legalPersons = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //search
    async searchBtn(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search
  }
  
}
</script>