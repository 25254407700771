<template>
  <div style="width: 100%">
    <div class="form__input">
      <input
        @click.prevent="openPopup()"
        type="text" readonly
        v-model="input"
      >
      <span>{{data_date.name}}</span>
      <div v-if="data_date.edit || data_date.edit == undefined">
        <a class="form__close date" v-if="input" @click.prevent="clear"></a>
      </div>
    </div>
    <Popup v-if="popup.fade" :popup="popup" :dateInput="input" @ReadyDate="ReadyDateOn" @close="popup.fade = !popup.fade" />
  </div>
</template>


<style >
  .form__close.date{
    right: -5px;
  }
</style>



<script>
import Popup from '@/components/app/Popup'


export default {
  props: ['data_date'],

  data: () => ({
    popup: {fade: false, mod: 'Date', title: 'Дата', date_input: '', min: '', modTime: ''},
    input: '',
    date1: '',
    date2: '',
  }),

  watch:{
    data_date: function(){
    
      
      if(this.data_date.data == '0000-00-00 00:00:00' || this.data_date.data == null || this.data_date.data == '0000-00-00' || this.data_date.data == ''){
        this.input = ''
      } else{
        if(this.data_date.mod == 'is-range'){

        }else if(this.data_date.mod){
          this.input = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.data_date.data))+ 
          '  в  ' + new Date(this.data_date.data).toLocaleTimeString().slice(0,-3)
          this.popup.date_input = this.data_date.data
        } else{
          if(this.data_date.data.search('-') != -1){
            this.input = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.data_date.data.replace(/-/g, "/")))
            this.popup.date_input = this.data_date.data
            this.$emit('resultData', {date: this.input, input: this.data_date.input})
          }
        }
      }
    }
  },

  created() {
    
    if(this.data_date.mod){
      this.popup.modTime = this.data_date.mod
    }
    if(this.data_date.data == '0000-00-00 00:00:00' || this.data_date.data == null || this.data_date.data == '0000-00-00' || this.data_date.data == ''){
      this.input = ''
    } else{
      if(this.data_date.mod == 'is-range'){
        this.date1 = this.data_date.data.split('-')[0];
        this.date2 = this.data_date.data.split('-')[1];
        this.input = this.data_date.data;
        this.$emit('resultData', {date: this.input, input: this.data_date.input})
      }else if(this.data_date.mod){
        this.input = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.data_date.data.replace(/-/g, "/")))
        + '  в  ' + new Date(this.data_date.data).toLocaleTimeString().slice(0,-3)
        this.popup.date_input = this.data_date.data
        this.$emit('resultData', {date: new Date(this.data_date.data), input: this.data_date.input})
      } else{
        if(new Date(this.data_date.data.replace(/-/g, "/")) != 'Invalid Date'){
          this.input = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.data_date.data.replace(/-/g, "/")))
          this.popup.date_input = this.data_date.data
        } else{
          this.input = this.data_date.data;
        }
        this.$emit('resultData', {date: this.input, input: this.data_date.input})
      }
    }
  },


  methods: {
    //dataPopup
    ReadyDateOn(data){

      if(data[0].date !== null){
        if(this.data_date.mod == 'is-range'){
          if(data[0].date.start !== undefined){
            this.date1 = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "2-digit"}).format(new Date(data[0].date.start));
            this.date2 = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "2-digit"}).format(new Date(data[0].date.end));
            this.input = this.date1 + '-' + this.date2;
            this.$emit('resultData', {date: this.input, input: this.data_date.input});
          }
        } else{
          this.input = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(data[0].date))
          if(this.data_date.mod){
            this.input += '  в  ' + new Date(data[0].date).toLocaleTimeString().slice(0,-3)
            this.$emit('resultData', {date: data[0].date, input: this.data_date.input})
          } else{

            this.$emit('resultData', {date: this.input, input: this.data_date.input})
          }
        }
      }
    },
    openPopup(){
      function date(date) {
        if (date) {
          var year = date.substr(6, 2);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date('20' + year + "-" + month + "-" + day);
          return resultDate;
        }
      }
      if(this.data_date.edit || this.data_date.edit == undefined){
        if(this.data_date.mod == 'is-range'){
          this.popup.date_input = {start: date(this.date1), end: date(this.date2)};
        } else{
          this.popup.date_input = this.data_date.data
        }
        if(this.data_date.min){
          if(this.data_date.min == '0000-00-00 00:00:00' || this.data_date.min == '0000-00-00'){
          } else{
            this.popup.min = this.data_date.min
            this.popup.fade = !this.popup.fade
          }
        } else if(this.data_date.min == undefined && this.data_date.min !== null){
          this.popup.fade = !this.popup.fade;
          const formattedDate = new Date().toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
          this.popup.min = formattedDate;
        } else{
        }
      }
    },
    clear(){
      this.input = ''
      this.$emit('resultData', {date: this.input, input: this.data_date.input})
    }
  },



  components: {
    Popup
  }
}
</script>