<template>
  <div class="title">
    <router-link to="/" v-if="$route.name === 'Home'" href="#">
      <img
        v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'"
        src="img/logo-white.svg" alt="">
    </router-link>
    <span v-else class="icon icon-arrow-left button-back" @click.prevent="back()"></span>
    <h1 :class="$route.name === 'Home' ? 'title-home' : ''">

      <a @click.prevent="linkChat" :class="$root.title[0].title && $root.title[0].title.length >= 25 ? 'more' : ''">
        {{$root.title[0].title}}
        <span v-if="$root.title[0].info">{{$root.title[0].info}}</span>
      </a>
    </h1>
    <a class="icon icon-menu" :class="{'active': data_is_active}" @click="closeMenu"></a>
  </div>
</template>

<style >
  .title-home{
    padding: 0 50px;
  }
  .title a.more{
    font-size: 13px;
    line-height: 1.2;
  }
  .button-back.hidden{
    opacity: 0;
    visibility: hidden;
  }
</style>


<script>

export default {
  props: ['data_is_active'],
  data:() => ({
    title: '',
    text: ''
  }),





  methods: {
    back(){
      this.$emit('update-data', false);
      if(window.location.search || (window.location).toString().slice(-1) == '?'){
        this.$router.go(-3)
      } else{
        this.$router.go(-1)
      }
    },


    linkChat(){
      if(this.$route.name === 'ChatMessage'){
        var owner_type = this.$route.params.id.slice(-5)
        if(owner_type == 'order'){var type = 'orders'} else{var type = 'quotes'}
        var id = this.$route.params.id.substr(0,this.$route.params.id.length - 6)
        this.$router.push('/' + type + '/'+ id)
      }
    },
    closeMenu(){
      this.$emit('update-data', !this.data_is_active);
      
      if(this.data_is_active){
        this.$root.overflowApp.flag = false
      }else{
        this.$root.overflowApp.flag = true
      }
      
    }
  },
}
</script>