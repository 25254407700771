<template>
  <div style="width: 100%">
    <div

      :class="'form__input form__input--more ' + (data_parent.none ? 'form__input--disabled' : '')"
    >
      <p>
        <img v-if="img" :src="img" alt="" />
        <input
          type="text"
          :data-id="input_id"
          v-model="input"
          :data-key="data_parent.key"
          @input="change"
          @blur="blur"
        />
      </p>
      <span style="min-height:15px">{{ data_parent.name }}</span>
      <div v-if="hidden != false">
        <a
          class="icon icon-more"
          v-if="!data_parent.none"
          @click.prevent="openList(data_parent.mod)"
        ></a>
        <a class="form__close" v-if="input" @click.prevent="cliner"></a>
      </div>
      <transition name="fade">
        <ul class="form__input__autocomplete" v-if="dropdown">
          <li
            v-for="(item, index) in arr"
            :key="index"
            style="display:flex"
            @click.prevent="autocompleteClick(item.id, item.name, item.phone, item.doc_support_manager_id, item.doc_support_manager_name, item.country_code)"
          >
            <img v-if="item.country_code && data_parent.mod == 'city'" style="width:17px;height:13px;margin-right:5px;margin-top:3px" :src="'https://crm-core.soft-pro.com/assets/img/flags/' +item.country_code.toLowerCase() + '.svg'" />{{ item.name }}
          </li>
          <li v-if="quantityList == '0'">{{$store.state.langText.nothing_found}}</li>
          <li v-if="quantityList > '5'" style="color: red; font-size: 13px">
            {{$store.state.langText.first_five}}
          </li>
        </ul>
      </transition>
    </div>
    <Clients :popup="clients" @close="closeList" v-if="clients" />
    <Contractors
      :popup="contractors"
      :data_contractors="data_parent"
      @close="closeList"
      v-if="contractors"
    />
    <City :popup="city" @close="closeCity" v-if="city" />
    <Team :popup="team" @close="closeList" v-if="team" />
    <LegalPersons
      :data_legal_persons="data_parent"
      :popup="legalPersons"
      @close="closeList"
      v-if="legalPersons"
    />
    <ClientsSource
      :popup="clientsSource"
      @close="closeList"
      v-if="clientsSource"
    />
    <ContractorsGroup
      :popup="contractorsGroup"
      @close="closeList"
      v-if="contractorsGroup"
    />
    <Services :popup="services" @close="closeList" v-if="services" />
    <CarType :popup="carType" @close="closeList" v-if="carType" />
    <Drivers :data_drivers="data_parent" :popup="drivers" @close="closeList" v-if="drivers" />
    <Car :data_cars="data_parent" :popup="car" @close="closeList" v-if="car" />
    <All :popup="all" :mod="data_parent.modType" @close="closeList" v-if="all" />
    <Trailers
      :data_trailers="data_parent"
      :popup="trailers"
      @close="closeList"
      v-if="trailers"
    />
    <Contact
      :hidden="hidden"
      :popup="contact"
      :data_contact="data_parent"
      @close="closeList"
      v-if="contact"
    />
    <Countries :popup="countries" @close="closeCity" v-if="countries" />
  </div>
</template>

<style >
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.form__input--more input {
  padding-right: 40px;
}
.form__input__autocomplete {
  max-height: 200px;
}
.form__input__autocomplete li {
  padding: 6px 4px;
  box-shadow: none;
  margin-bottom: 0;
}
.pop-up{
  overflow: visible;
}
</style>


<script>
import Clients from "@/components/selection/Clients";
import Contractors from "@/components/selection/Contractors";
import City from "@/components/selection/City";
import Team from "@/components/selection/Team";
import LegalPersons from "@/components/selection/LegalPerson";
import ClientsSource from "@/components/selection/ClientsSource";
import ContractorsGroup from "@/components/selection/ContractorsGroup";
import Services from "@/components/selection/Services";
import CarType from "@/components/selection/CarType";
import Drivers from "@/components/selection/Drivers";
import Car from "@/components/selection/Car";
import Trailers from "@/components/selection/Trailers";
import Contact from "@/components/selection/Contact";
import Countries from "@/components/selection/Countries";
import All from "@/components/selection/All";

export default {
  name: "Autocomplete",
  props: ["data_parent", "hidden"],

  data: () => ({
    input: null,
    input_id: "",
    arr: [],
    dropdown: false,
    img: "",
    //popup
    clients: false,
    contractors: false,
    city: false,
    team: false,
    legalPersons: false,
    clientsSource: false,
    contractorsGroup: false,
    services: false,
    carType: false,
    drivers: false,
    car: false,
    all: false,
    trailers: false,
    contact: false,
    countries: false,

    //pagination
    check: "",

    quantityList: "",
    params: {
      start: 0,
      limit: 5,
    },
  }),

  watch: {
    data: function () {
      // this.input = this.data_parent.input;
      // this.check = this.input;
      // this.input_id = this.data_parent.inputId;
      // if (this.data_parent.img) {
      //   this.img =
      //     "https://crm-core.soft-pro.com/assets/img/flags/" +
      //     this.data_parent.img.toLowerCase() +
      //     ".svg";
      // }
    },
  },

  created() {
    this.input = this.data_parent.input;
    this.check = this.input;
    this.input_id = this.data_parent.inputId;
    if (this.data_parent.img) {
      this.img =
        "https://crm-core.soft-pro.com/assets/img/flags/" +
        this.data_parent.img.toLowerCase() +
        ".svg";
    }
  },
  methods: {
    //cliner
    cliner() {
      var input = this.data_parent.key;
      this.input = "";
      this.input_id = "";
      this.check = "";
      this.img = "";
      this.$emit("result", { id: "", name: "", input, img: "" });
    },

    //data
    async change() {
      if (this.input.length >= 3) {
        var data = "search=" + this.input;
        var dispatch;

        if(this.data_parent.mod == "contact" && this.data_parent.idClient){
          var id = this.data_parent.idClient
          var type = this.data_parent.typeClient
          const contact = await this.$store.dispatch('getContactsPersons', {id, type, data});
          console.log(contact);
          
          var app = this
          app.arr = []
          contact.data.list.forEach(function(item, index){
            if(item.id && index <= 4){
              app.arr.push(item)
            }
          })
          this.quantityList = this.arr.length
        }else {
          if(this.data_parent.idContractor){
            data += '&f[contractor_id]='+this.data_parent.idContractor
          }

          //clients
          if (this.data_parent.mod == "clients") {
            dispatch = "postFiltersClients";
          }

          //contractors
          if (this.data_parent.mod == "contractors") {
            if (this.data_parent.filter) {
              data += "&f[" + this.data_parent.filter + "]=1";
            }
            if (this.data_parent.idClient) {
              data += "&f[client_id]=" + this.data_parent.idClient;
            }
            if (this.data_parent.idMainContractor) {
              data += "&f[main_contractor]=" + this.data_parent.idMainContractor;
            }
            dispatch = "postFiltersContractors";
          }

          //city
          if (this.data_parent.mod == "city") {
            dispatch = "postFiltersCity";
          }
          
          //team
          if (this.data_parent.mod == "team") {
            dispatch = "postFiltersTeam";
          }

          //legalPersons
          if (this.data_parent.mod == "legalPersons") {
            dispatch = "postFiltersLegalPerson";
          }

          //clientsSource
          if (this.data_parent.mod == "clientsSource") {
            dispatch = "postFiltersClientsSource";
          }

          //contractorsGroup
          if (this.data_parent.mod == "contractorsGroup") {
            dispatch = "postFiltersContractorsGroup";
          }

          //services
          if (this.data_parent.mod == "services") {
            if(this.$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || this.$store.state.UniqueID == 'SoftProCrmAppRioTrans'){
              data += '&f[service_group]=0&f[service_type]=1'
            }
            dispatch = "postFiltersServices";
          }
          
          //car
          if (this.data_parent.mod == "car") {
            dispatch = "postFiltersCar";
          }

          //carType
          if (this.data_parent.mod == "carType") {
            dispatch = "postFiltersCarType";
          }

          //drivers
          if (this.data_parent.mod == "drivers") {
            dispatch = "postFiltersDrivers";
          }

          //trailers
          if (this.data_parent.mod == "trailers") {
            dispatch = "postFiltersTrailers";
          }

          //countries
          if (this.data_parent.mod == "countries") {
            dispatch = "postFiltersCountry";
          }



          /// demo
          if (this.data_parent.modType == "car") {
            dispatch = "postFiltersCar";
          }
          if(this.data_parent.modType == 'org'){
            dispatch = 'getOrg'
          }
          if(this.data_parent.modType == 'line'){
            dispatch = 'getlines'
          }
          if(this.data_parent.modType == 'courtAction'){
            dispatch = 'courtAction'
          }
          if(this.data_parent.modType == 'carModels'){
            dispatch = 'carModels'
          }
          if(this.data_parent.modType == 'carManufacturers'){
            dispatch = 'carManufacturers'
          }
          if(this.data_parent.modType == 'servicesName'){
            dispatch = 'servicesName'
          }
          if(this.data_parent.modType == 'incomeNDS'){
            dispatch = 'incomeNDS'
          }
          if(this.data_parent.modType == 'paymentTypes'){
            dispatch = 'paymentTypes'
          }
          if(this.data_parent.modType == 'conditions'){
            dispatch = 'conditions'
          }
          if(this.data_parent.modType == 'cargo'){
            dispatch = 'cargo'
          }
          if(this.data_parent.modType == 'route'){
            dispatch = 'route'
          }
          
          var res = await this.$store.dispatch(dispatch, {
            params: this.params,
            data: data,
          });

          this.arr = res.data.list;
          this.quantityList = res.data.total_num;
        }


        this.dropdown = true;
      } else {
        this.dropdown = false;
      }
    },

    blur() {
      if(this.check !== this.input) {
        this.input = this.check;
        this.dropdown = false;

        var input = this.data_parent.key;
        this.$emit("result", { id: this.input_id, name: this.input, input });
      }
    },

    autocompleteClick(id, name, phone, doc_menedger_id, doc_menedger_name, img) {
      this.input = name;
      this.input_id = id;
      this.dropdown = false;

      var input = this.data_parent.key;
      if (phone && this.data_parent.mod == "drivers" || this.data_parent.mod == "contact") {
        this.$emit("result", { id, name, input, phone: phone });
      } else if (doc_menedger_id && doc_menedger_id !== "0") {
        this.$emit("result", {
          id,
          name,
          input,
          doc_menedger_id,
          doc_menedger_name,
        });
      } else if(img){
        this.img =
        "https://crm-core.soft-pro.com/assets/img/flags/" +
        img.toLowerCase() +
        ".svg";
        this.$emit("result", {
          id,
          name,
          input,
        });
      } else {
        this.$emit("result", { id, name, input });
      }
    },

    //popup list
    openList(component) {
      if (component == "clients") {
        this.clients = !this.clients;
      }
      if (component == "contractors") {
        this.contractors = !this.contractors;
      }
      if (component == "city") {
        this.city = true;
      }
      if (component == "team") {
        this.team = true;
      }
      if (component == "legalPersons") {
        this.legalPersons = true;
      }
      if (component == "clientsSource") {
        this.clientsSource = true;
      }
      if (component == "contractorsGroup") {
        this.contractorsGroup = true;
      }
      if (component == "services") {
        this.services = true;
      }
      if (component == "car") {
        this.car = true;
      }
      if (component == "all") {
        this.all = true;
      }
      if (component == "carType") {
        this.carType = true;
      }
      if (component == "drivers") {
        this.drivers = true;
      }
      if (component == "trailers") {
        this.trailers = true;
      }
      if (component == "contact" && this.data_parent.idClient) {
        this.contact = true;
      }
      if (component == "countries") {
        this.countries = true;
      }
      // this.$root.overflowApp.flag = true
    },
    closeList(data) {
      this.clients = false;
      this.contractors = false;
      this.team = false;
      this.legalPersons = false;
      this.clientsSource = false;
      this.contractorsGroup = false;
      this.services = false;
      this.carType = false;
      this.drivers = false;
      this.car = false;
      this.all = false;
      this.trailers = false;
      this.contact = false;
      this.countries = false;
      // this.$root.overflowApp.flag = false
      if (data) {
        //data
        this.input = data.name;
        this.input_id = data.id;
        this.check = data.name;

        var input = this.data_parent.key;
        var name = data.name;
        var id = data.id;
        var doc_menedger_id = data.doc_menedger_id;
        var doc_menedger_name = data.doc_menedger_name;
        if (data.phone) {
          this.$emit("result", { id, name, input, phone: data.phone });
        } else if (data.doc_menedger_id && data.doc_menedger_id !== "0") {
          this.$emit("result", {
            id,
            name,
            input,
            doc_menedger_id,
            doc_menedger_name,
          });
        } else {
          this.$emit("result", { id, name, input, data: data.item });
        }
      }
    },
    closeCity(data) {
      if (data) {
        this.input = data.name;
        this.input_id = data.id;
        if (data.img) {
          this.img =
            "https://crm-core.soft-pro.com/assets/img/flags/" + data.img + ".svg";
        } else {
          this.img = "";
        }
        this.$emit("result", {
          id: data.id,
          name: data.name,
          input: this.data_parent.key,
          img: this.img,
        });
      }
      this.city = false;
      this.countries = false;
    },
  },
  components: {
    Clients,
    Contractors,
    City,
    Team,
    LegalPersons,
    ClientsSource,
    ContractorsGroup,
    Services,
    CarType,
    Car,
    Trailers,
    Contact,
    Countries,
    Drivers,
    All,
  },
};
</script>