<template>
  <div id="app">
    <component :is="layout">
      <router-view v-slot="{ Component }">
        <transition name="fade"r>
          <component :is="Component" />
        </transition>
      </router-view>
    </component>
    
    <div v-if="updateApp">
      <div class="pop-up-bg"></div>
      <div class="pop-up">
        <div>
          <h2 style="font-weight: 400; text-align: center">
            {{ $store.state.langText.update_required }}
          </h2>
          <i class="icon-update"></i>
          <div class="pop-up__btn" style="justify-content: center">
            <a class="btn btn-primary" @click.prevent="updateApp2()">{{
              $store.state.langText.refresh_page
              }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="progress_bar">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="mobile-rotate">
      <img :src="url + '/img/turn.svg'" alt="" />
    </div>
  </div>
</template>

<style>
@import "assets/app.css";
@import "assets/custom.css";

.form__input--plagin {
  margin-bottom: 0;
  width: 100%;
}

.form__input--plagin .form__input {
  width: 100% !important;
}

.list-not {
  margin-top: 20px;
}

.pop-up {
  overflow: auto;
}
</style>

<script>
import Axios from "axios";
import LoginLayout from "@/layouts/LoginLayout";
import OtherLayout from "@/layouts/OtherLayout";

export default {
  data() {
    return {
      updateApp: false,
      interval: null,
      url: "",
    };
  },

  async created() {

    // if (
    //   this.$store.state.UniqueID === "SoftProCrmAppRioTransTest" ||
    //   this.$store.state.UniqueID === "SoftProCrmAppRioTrans"
    // ) {
    //   localStorage.setItem("lang", "ua");
    // } else {
      if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang", "ru");
      }
    // }

    this.url = window.location.origin;

    this.interval = setInterval(() => {
      if (window.update) {
        this.updateApp = true;
      }
    }, 1000);

    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.add("dark");
    }

    try {
      
      // Обновление языка каждые 1000 мс
      setInterval(async () => {
        let lang = localStorage.getItem('lang');
        if(lang != this.lang){
          var lang_json = await this.$store.dispatch('getLang', lang || 'ru');
          this.$store.state.langText = lang_json.data;
          this.lang = lang;
        }
      }, 1000);
      
    } catch (error) {
      console.error(error);
    }


    Axios.interceptors.response.use(
      (response) => {

        if (response.data.error === "invalid user api key") {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        } else if (
          response.status === 401 &&
          response.config &&
          !response.config.__isRetryRequest
        ) {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
        if (
          response.data.error === "empty data" ||
          response.data.error === "access denied"
        ) {
          this.$router.push(this.$route.meta.url);
        }
        return response;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );


  },

  methods: {
    updateApp2() {
      this.updateApp = false;
      window.update = false;
      document.location.reload(true);
    },
  },

  computed: {
    layout() {
      if (this.$route.meta.layout === "login") {
        return "login-layout";
      } else if (this.$route.meta.layout === "other") {
        return "other-layout";
      } else {
        return "other-layout";
      }
    },
  },

  components: {
    LoginLayout,
    OtherLayout,
  },
};
</script>
