<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2>{{$store.state.langText.employees}}</h2>
        <Search :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">{{$store.state.langText.nothing_found}}</p>
        <ul class="pop-up__list-team block-list">
          <li
            v-for="(team, index) in teams"
            :key="index"
            :data-id="team.id"
            @click.prevent="close(team.id, team.name)"
          >
            <div class="pop-up__list-team__img">
              <img v-if="team.img_url" :src="team.img_url" alt="team-logo">
              <img v-if="!team.img_url" src="https://crm-core.soft-pro.com/assets/img/user-img.svg" alt="team-logo">
            </div>
            <div style="width: calc(100% - 45px)">
              <h4>{{team.name}}</h4>
              <span>{{team.organization}}</span>
              <a class="icon icon-arrow-right"></a>
            </div>
          </li>
        </ul>
        <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
      </div>
    </div>
  </transition>
</template>



<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"

export default {
  props: ['popup', 'input'],

  data:() => ({
    teams: [],
    search: '',
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    listNot: false
  }),
  async mounted() {
    //base
    const team = await this.$store.dispatch('getTeam', this.params);
    this.teams = team.data.list;
    this.quantityList = team.data.total_num
  },
  methods: {
    close(id, name){
      if(this.input){
        this.$emit('close', {id, name, input: this.input})
      } else{
        this.$emit('close', {id, name})
      }
    },

    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData
      var arrFilters = await this.$store.dispatch('postFiltersTeam', {params: this.params, data: this.arrFilters})
      this.teams = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //search
    async searchBtn(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search
  }
  
}
</script>