import axios from 'axios'
import router from '@/router'

export default {
  actions: {
    async getCurrentUser({ commit }) {
      var result;
      // console.log('Root in getCurrentUser:', root);
      try {
        return await axios
          .get('/api/team_users/auth_info/', {})
          .then(resp => {

            this.state.add = {
              can_add_city: resp.data.detail.can_add_city || false,
              can_add_client: resp.data.detail.can_add_client || false,
              can_add_contractor: resp.data.detail.can_add_contractor || false,
              can_add_driver: resp.data.detail.can_add_driver || false,
              can_add_lead: resp.data.detail.can_add_lead || false,
              can_add_order: resp.data.detail.can_add_order || false,
              can_add_quote: resp.data.detail.can_add_quote || false,
              can_add_team_user: resp.data.detail.can_add_team_user || false,
              can_add_user: resp.data.detail.can_add_user || false,
              is_dispatcher: (this.state.UniqueID === 'SoftProCrmAppRioTransTest' || this.state.UniqueID === 'SoftProCrmAppRioTrans') ? (resp.data.detail.is_dispatcher !== 0) : false
            };

            this.state.edit = {
              can_edit_city: resp.data.detail.can_edit_city || false,
              can_edit_client: resp.data.detail.can_edit_client || false,
              can_edit_contractor: resp.data.detail.can_edit_contractor || false,
              can_edit_driver: resp.data.detail.can_edit_driver || false,
              can_edit_lead: resp.data.detail.can_edit_lead || false,
              can_view_quote: resp.data.detail.can_view_quote || false,
              can_edit_plan_calculation: resp.data.detail.can_edit_plan_calculation || false,
              can_view_plan_calculation: resp.data.detail.can_view_plan_calculation || false,
              can_view_sum_income: resp.data.detail.can_view_sum_income || false,
              can_edite_data_orders: (this.state.UniqueID === 'SoftProCrmAppRioTransTest' || this.state.UniqueID === 'SoftProCrmAppRioTrans') ? (resp.data.detail.can_edit_date_create_doc || false) : false
            };

            window.crmUserID = resp.data.detail.id
            
            return resp
          })
          .catch(err => {
            if(err.status !== '200'){
              localStorage.removeItem('token')
              delete axios.defaults.headers.common['User-Auth-Key']
              router.push('/login');
            }
            
          })
      } catch (e) {
        throw e;
      }
    },



    async getCurrentUserStatistics() {
      var result;
      try {
        return await axios
          .get('/api/team_users/auth_info/stats/', {

          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

  }
}