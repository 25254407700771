import axios from 'axios'


export default {
  
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {}
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    async login({commit}, user){
      return await new Promise((resolve, reject) => {
        commit('auth_request')
        axios('/api/users/auth/', {
          
          data: user,
          method: 'POST'
        })
        .then(resp => {
          const token = resp.data.token;
          const user = resp.data.user;
          if(token){
            localStorage.setItem('token', token);
            axios.defaults.headers.common['User-Auth-Key'] = token;
            commit('auth_success', token, user)
            resolve(resp)
          } else{
            localStorage.removeItem('token')
            delete axios.defaults.headers.common['User-Auth-Key']
            commit('auth_error')
            reject(resp.data.error)
          }
          return resp
        })
        .catch(err => {
          localStorage.removeItem('token')
          delete axios.defaults.headers.common['User-Auth-Key']
          commit('auth_error')
          reject(err)
          return err
        })

      })
    },
    

    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['User-Auth-Key']
        resolve()
      })
     },



     async updatePassword({commit}, updatePassword) {
      var result;
      try {
        return await axios
          ('/api/users/update/', {
            method: 'POST',
            data: updatePassword
            
          })
          .then(response => (result = response, console.log(response)))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  }
}