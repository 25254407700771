import axios from 'axios'

export default {
  actions: {


    async getTasks({ commit }, { params: { start, limit }, data }) {
      var result;
      try {
        return await axios
          ('/api/tasks/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: { filters: data }
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getTaskId({commit}, id) {
      var result;
      try {
        return await axios
          .get("/api/tasks/get/" + id + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateTasks({ commit }, data) {
      try {
        return await axios
          ('/api/tasks/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },


    async getTasksTypes({ commit }, { id }) {
      try {
        return await axios
          .get('/api/tasks_types/list/?current_id=' + id, {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getTasksContactsTypes({ commit }) {
      try {
        return await axios
          .get('/api/tasks_contacts_types/list/', {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

  }
}