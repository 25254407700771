<template>
  <div class="filters-active">
    <h4 :class="btn ? 'active' : ''" @click="selectedFilters">
      {{ $store.state.langText.selected_filters }}
      <i></i>
    </h4>
    <ul v-if="btn">
      <li v-for="(item, index) in items" :key="index">
        {{ item.name }}: {{ item.valname }} <a @click="sendChange(item)"></a>
      </li>
    </ul>
  </div>
</template>


<style>
.pop-up .block-list .filters-active h4 {
  font-size: 16px !important;
}
.filters-active ul li {
  display: inline-block;
}
</style>


<script>
export default {
  props: ["selectFilters"],

  data: () => ({
    items: "",
    btn: true,
  }),

  watch: {
    selectFilters: function () {
      this.items = this.selectFilters;
    },
  },

  created() {
    this.items = this.selectFilters;
  },

  methods: {
    selectedFilters() {
      this.btn = !this.btn;
    },

    sendChange(data) {
      this.$emit("sendChange", data);
    },
  },
};
</script>