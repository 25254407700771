
export function send_format_date(date) {
  if (date) {
    var year = date.substr(6, 4);
    var day = date.substr(0, 2);
    var month = date.substr(3, 2);
    var resultDate =
      new Date(year + "-" + month + "-" + day).getTime() / 1000;
    return resultDate;
  } else{
    return '';
  }
}


export function view_format_date(dateString, is_time = false) {
  // Проверка на null или некорректные даты
  if (!dateString || dateString === '0000-00-00' || dateString === '0000-00-00 00:00:00') {
    // console.error('Некорректная дата:', dateString);
    return ''; // Или можно вернуть 'Некорректная дата'
  }

  // Преобразование строки даты в объект Date
  const date = new Date(dateString);

  // Проверка на корректность даты
  if (isNaN(date.getTime())) {
    // console.error('Некорректная дата:', dateString);
    return ''; // Или выбросить исключение
  }

  // Форматирование даты
  if (!is_time) {
    // Форматирование для даты без времени
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('ru-RU', options); // Например, '30 сентября 2024'
  } else {
    // Форматирование для даты с временем
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };

    const formattedDate = date.toLocaleDateString('ru-RU', dateOptions); // Например, '30.09.2024'
    const formattedTime = date.toLocaleTimeString('ru-RU', timeOptions); // Например, '14:00'

    return `${formattedDate} ${formattedTime}`; // Например, '30.09.2024 14:00'
  }
}


export function name_with_language(list, custom_name = 'name') {
  let lang = localStorage.getItem('lang') || 'ru';

  if(list[custom_name + '_' + lang]){
    return list[custom_name + '_' + lang];
  } else{
    return list[custom_name];
  }
}