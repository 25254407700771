<template>
  <div>
    <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
    <div class="pop-up">
      <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>

      <!-- car -->
      <div v-if="data_handbook.modPopup == 'car'">
        <h2>{{ $store.state.langText.auto }}</h2>
        <div class="form__input">
          <input type="text" v-model="car.name" />
          <span>{{ $store.state.langText.name }}</span>
        </div>
        <div class="form__input" v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        ">
          <input type="text" v-model="car.gov_number" />
          <span>{{ $store.state.langText.state_number }}</span>
        </div>
        <div class="form__input" v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        ">
          <input type="text" v-model="car.year_manufacture" />
          <span>{{ $store.state.langText.year_of_manufacture }}</span>
        </div>
        <div v-if="!data_handbook.contractor_id">
          <Autocomplete v-if="
            $store.state.UniqueID == 'SoftProCrmAppRioTransTest' ||
            $store.state.UniqueID == 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              filter: 'is_main',
              mod: 'contractors',
              key: 'counterparties',
              input: car.counterparties,
              inputId: car.counterparties_id,
            }" @result="autocompleteResult" />
          <Autocomplete v-if="
            $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
            $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              mod: 'contractors',
              key: 'counterparties',
              input: car.counterparties,
              inputId: car.counterparties_id,
            }" @result="autocompleteResult" />
        </div>
        <Autocomplete v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        " :data_parent="{
            name: $store.state.langText.driver,
            mod: 'drivers',
            key: 'drivers',
            input: car.drivers,
            inputId: car.drivers_id,
          }" @result="autocompleteResult" />
        <Autocomplete v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        " :data_parent="{
            name: $store.state.langText.trailer,
            mod: 'trailers',
            key: 'trailer',
            input: car.trailer,
            inputId: car.trailer_id,
          }" @result="autocompleteResult" />
      </div>

      <!-- trailers -->
      <div v-if="data_handbook.modPopup == 'trailers'">
        <h2>{{ $store.state.langText.trailers }}</h2>
        <div class="form__input">
          <input type="text" v-model="trailers.name" />
          <span>{{ $store.state.langText.number }}</span>
        </div>
        <div v-if="!data_handbook.contractor_id">
          <Autocomplete v-if="
            $store.state.UniqueID == 'SoftProCrmAppRioTransTest' ||
            $store.state.UniqueID == 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              filter: 'is_main',
              mod: 'contractors',
              key: 'counterparties',
              input: trailers.counterparties,
              inputId: trailers.counterparties_id,
            }" @result="autocompleteResult" />
          <Autocomplete v-if="
            $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
            $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              mod: 'contractors',
              key: 'counterparties',
              input: trailers.counterparties,
              inputId: trailers.counterparties_id,
            }" @result="autocompleteResult" />
        </div>
        <Autocomplete v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        " :data_parent="{
            name: $store.state.langText.driver,
            mod: 'drivers',
            key: 'drivers',
            input: trailers.drivers,
            inputId: trailers.drivers_id,
          }" @result="autocompleteResult" />
      </div>

      <!-- drivers -->
      <div v-if="data_handbook.modPopup == 'drivers'">
        <h2>{{ $store.state.langText.drivers }}</h2>
        <div class="form__input">
          <input type="text" v-model="drivers.name" />
          <span>{{ $store.state.langText.first_name }}</span>
        </div>
        <div class="form__input">
          <input type="text" v-model="drivers.phone" />
          <span>{{ $store.state.langText.phone }}</span>
        </div>
        <div class="form__input" v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        ">
          <p class="form__input__textarea-p">{{ drivers.comment }}</p>
          <textarea class="form__input__textarea" v-model="drivers.comment" />
          <span>{{ $store.state.langText.comments }}</span>
        </div>
        <div v-if="!data_handbook.contractor_id">
          <Autocomplete v-if="
            $store.state.UniqueID == 'SoftProCrmAppRioTransTest' ||
            $store.state.UniqueID == 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              filter: 'is_main',
              mod: 'contractors',
              key: 'counterparties',
              input: drivers.counterparties,
              inputId: drivers.counterparties_id,
            }" @result="autocompleteResult" />
          <Autocomplete v-if="
            $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
            $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
          " :data_parent="{
              name: $store.state.langText.contractor,
              mod: 'contractors',
              key: 'counterparties',
              input: drivers.counterparties,
              inputId: drivers.counterparties_id,
            }" @result="autocompleteResult" />
        </div>
        <Autocomplete v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        " :data_parent="{
            name: $store.state.langText.auto,
            mod: 'car',
            key: 'car',
            input: drivers.car,
            inputId: drivers.car_id,
          }" @result="autocompleteResult" />
        <Autocomplete v-if="
          $store.state.UniqueID !== 'SoftProCrmAppRioTransTest' &&
          $store.state.UniqueID !== 'SoftProCrmAppRioTrans'
        " :data_parent="{
            name: $store.state.langText.trailer,
            mod: 'trailers',
            key: 'trailer',
            input: drivers.trailer,
            inputId: drivers.trailer_id,
          }" @result="autocompleteResult" />
      </div>

      <!-- contractors -->
      <div v-if="data_handbook.modPopup == 'contractors'">
        <h2>{{ $store.state.langText.contractor }}</h2>
        <div class="form__input">
          <input type="text" v-model="counterparties.name" />
          <span>{{ $store.state.langText.name }}</span>
        </div>
        <div class="form__row form__row-50">
          <div class="form__radio">
            <label>
              <input type="radio" name="people" value="2" v-model="counterparties.people" />
              <span>{{ $store.state.langText.physical_person_min }}</span>
            </label>
          </div>
          <div class="form__radio">
            <label>
              <input type="radio" name="people" value="1" v-model="counterparties.people" />
              <span>{{ $store.state.langText.legal_entity }}</span>
            </label>
          </div>
        </div>
      </div>

      <!-- clients -->
      <div class="handbook_checkbox" v-if="data_handbook.modPopup == 'clients'">
        <h2>{{ $store.state.langText.client }}</h2>
        <div style="max-height: 74vh;overflow: scroll;">
          <div class="form__input">
            <input type="text" v-model="fields.name" />
            <span>{{ $store.state.langText.name }}</span>
          </div>

          <div class="form__input">
            <input type="text" v-model="fields.nameFull" />
            <span>{{ $store.state.langText.full_name }}</span>
          </div>

          <!-- <div class="form__input form__input--status">
						<b :style="{ color: fields.type_direction_color }">{{
							fields.status
						}}</b>
						<span>{{$store.state.langText.status}}</span>
					</div> -->

          <Autocomplete :data_parent="{
            name: $store.state.langText.source,
            mod: 'clientsSource',
            key: 'clientsSource',
            input: fields.clientsSource,
            inputId: fields.clientsSource_id,
          }" @result="autocompleteResult" />

          <div class="form__input">
            <input type="text" v-model="fields.additionalSign" />
            <span>{{ $store.state.langText.additional_feature }}</span>
          </div>

          <div class="form__checkbox">
            <label>
              <input type="checkbox" v-model="fields.specialControl" />
              <span>{{ $store.state.langText.specialControl }}</span>
            </label>
          </div>

          <div class="form__checkbox">
            <label>
              <input type="checkbox" v-model="fields.blacklist" />
              <span>{{ $store.state.langText.blackList }}</span>
            </label>
          </div>

          <Autocomplete :data_parent="{
            name: $store.state.langText.city,
            mod: 'city',
            key: 'city',
            input: fields.city,
            inputId: fields.city_id,
          }" @result="autocompleteResult" />

          <Autocomplete :data_parent="{
            name: $store.state.langText.manager,
            mod: 'team',
            key: 'manager',
            input: fields.manager,
            inputId: fields.manager_id,
          }" @result="autocompleteResult" />

          <Autocomplete :data_parent="{
            name: $store.state.langText.doc_support_manager,
            mod: 'team',
            key: 'documentManager',
            input: fields.documentManager,
            inputId: fields.documentManager_id,
          }" @result="autocompleteResult" />

          <h2>{{ $store.state.langText.company_type }}</h2>
          <div class="form__row form__row-wrap">
            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.type.forwarder" />
                <span>{{ $store.state.langText.expeditor }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.type.producer" />
                <span>{{ $store.state.langText.proizvoditel }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.type.motorShow" />
                <span>{{ $store.state.langText.avtosalon }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.type.broker" />
                <span>{{ $store.state.langText.broker }}</span>
              </label>
            </div>
          </div>

          <h2>{{ $store.state.langText.direction }}</h2>
          <div class="form__row form__row-wrap">
            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.direction.imports" />
                <span>{{ $store.state.langText.import }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.direction.export" />
                <span>{{ $store.state.langText.is_export }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.direction.transit" />
                <span>{{ $store.state.langText.is_transit }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.direction.internal" />
                <span>{{ $store.state.langText.interior }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.direction.doNotCarry" />
                <span>{{ $store.state.langText.dont_carry_them }}</span>
              </label>
            </div>
          </div>

          <h2>{{ $store.state.langText.services }}</h2>
          <div class="form__row form__row-wrap">
            <div class="form__checkbox">
              <label>
                <input type="checkbox" v-model="fields.service.freight" />
                <span>{{ $store.state.langText.req - serv_type - freight }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.auto" />
                <span>{{ $store.state.langText.avto }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.railway" />
                <span>{{ $store.state.langText.gd }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.aviation" />
                <span>{{ $store.state.langText.avia }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.lcl" />
                <span>{{ $store.state.langText.lcl }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.teo" />
                <span>{{ $store.state.langText.teo }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client" v-model="fields.service.transshipment" />
                <span>{{ $store.state.langText.perevalka }}</span>
              </label>
            </div>

            <div class="form__checkbox">
              <label>
                <input type="checkbox" :disabled="!$store.state.edit.can_edit_client"
                  v-model="fields.service.customsCleaning" />
                <span>{{ $store.state.langText.customs_cleaning }}</span>
              </label>
            </div>
          </div>

          <div class="form__input">
            <input type="text" v-model="fields.loads" />
            <span>{{ $store.state.langText.cargoes }}</span>
          </div>
        </div>
      </div>

      <div v-if="!templateDeleteBtn">
        <!-- templatesUnloading -->
        <div v-if="data_handbook.modPopup == 'templatesUnloading'">
          <h2>№{{ +data_handbook.quantityList + 1 }}</h2>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressClearanceOut }}
            </p>
            <textarea class="form__input__textarea" type="text" v-model="templates.addressClearanceOut" />
            <span>{{ $store.state.langText.customs_address_to }}</span>
          </div>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressUnloading }}
            </p>
            <textarea class="form__input__textarea" type="text" v-model="templates.addressUnloading" />
            <span>{{ $store.state.langText.unloading_address }}</span>
          </div>

          <Autocomplete :data_parent="{
            name: $store.state.langText.unloading_point,
            mod: 'city',
            key: 'unloadingPoint',
            input: templates.unloadingPoint,
            inputId: templates.unloadingPoint_id,
            img: templates.unloadingPoint_img,
          }" @result="autocompleteResult" />
        </div>

        <!-- templatesDownload -->
        <div v-if="data_handbook.modPopup == 'templatesDownload'">
          <h2>№{{ +data_handbook.quantityList + 1 }}</h2>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressClearanceIn }}
            </p>
            <textarea class="form__input__textarea" type="text" v-model="templates.addressClearanceIn" />
            <span>{{ $store.state.langText.customs_address_from }}</span>
          </div>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressDownload }}
            </p>
            <textarea class="form__input__textarea" type="text" v-model="templates.addressDownload" />
            <span>{{ $store.state.langText.download_address }}</span>
          </div>

          <Autocomplete :data_parent="{
            name: $store.state.langText.loading_point,
            mod: 'city',
            key: 'downloadPoint',
            input: templates.downloadPoint,
            inputId: templates.downloadPoint_id,
            img: templates.downloadPoint_img,
          }" @result="autocompleteResult" />

          <div class="form__input">
            <input type="text" v-model="templates.cargo" />
            <span>{{ $store.state.langText.cargo }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="pop-up--delete">
          <div class="img warning"></div>
          <h3>{{ $store.state.langText.want_to_delete }}</h3>
        </div>
      </div>

      <p v-if="emty" style="color: red; padding: 0px 0 15px">{{ $store.state.langText.enter_all_data }}</p>
      <div class="pop-up__btn">
        <a class="btn btn-secondary" @click.prevent="templateDelete" :style="(data_handbook.modPopup == 'templatesUnloading' ||
            data_handbook.modPopup == 'templatesDownload') &&
            data_handbook.mod == 'update'
            ? 'background:#EB5757'
            : 'opacity:0;visibility:hidden'
          " href="#">{{ $store.state.langText.delete }}</a>
        <a class="btn btn-primary" @click.prevent="save()">{{ $store.state.langText.save2 }}</a>
      </div>
    </div>
  </div>
</template>


<style>
.handbook_checkbox .form__row-wrap div span {
  font-size: 13px;
}
</style>



<script>
export default {
  props: ["data_handbook"],

  data() {
    return {
      //popup
      emty: false,
      templateDeleteBtn: false,
      car: {
        name: "",
        gov_number: "",
        year_manufacture: "",
        counterparties: "",
        counterparties_id: "",
        drivers: "",
        drivers_id: "",
        trailer: "",
        trailer_id: "",
      },
      trailers: {
        name: "",
        counterparties: "",
        counterparties_id: "",
        drivers: "",
        drivers_id: "",
      },
      drivers: {
        name: "",
        phone: "",
        comment: "",
        counterparties: "",
        counterparties_id: "",
        car: "",
        car_id: "",
        trailer: "",
        trailer_id: "",
      },
      counterparties: {
        name: "",
        people: "2",
      },
      templates: {
        addressClearanceOut: "",
        addressUnloading: "",
        unloadingPoint: "",
        unloadingPoint_id: "",
        unloadingPoint_img: "",

        addressClearanceIn: "",
        addressDownload: "",
        downloadPoint: "",
        downloadPoint_id: "",
        downloadPoint_img: "",
        cargo: "",
      },

      fields: {
        name: null,
        nameFull: null,
        type_direction_color: '#0286E5',
        clientsSource: '',
        clientsSource_id: '',
        additionalSign: null,
        specialControl: false,
        blacklist: false,
        cityImg: '/img/country/ua.png',
        city: null,
        city_id: null,
        address: null,
        site: null,
        manager: null,
        manager_id: null,
        documentManager: null,
        documentManager_id: null,
        type: {
          forwarder: false,
          producer: false,
          motorShow: false,
          broker: false,
        },
        direction: {
          imports: false,
          export: false,
          transit: false,
          internal: false,
          doNotCarry: false,
        },
        service: {
          freight: false,
          auto: false,
          railway: false,
          aviation: false,
          lcl: false,
          teo: false,
          transshipment: false,
          customsCleaning: false
        },
        loads: null
      },
    };
  },

  created() {

    if (this.data_handbook.contractor_id) {
      this.car.counterparties_id = this.data_handbook.contractor_id;
      this.trailers.counterparties_id = this.data_handbook.contractor_id;
      this.drivers.counterparties_id = this.data_handbook.contractor_id;
    }

    if (this.data_handbook.modPopup == "car" && this.data_handbook.mod == "update") {
      this.car.name = this.data_handbook.info.name;
      this.car.gov_number = this.data_handbook.info.gov_number;
      this.car.year_manufacture = this.data_handbook.info.year_manufacture;
      this.car.counterparties = this.data_handbook.info.contractor_name;
      this.car.counterparties_id = this.data_handbook.info.contractor_id;
      this.car.drivers = this.data_handbook.info.driver_name;
      this.car.drivers_id = this.data_handbook.info.driver_id;
      this.car.trailer = this.data_handbook.info.trailer_name;
      this.car.trailer_id = this.data_handbook.info.trailer_id;
    }
    if (this.data_handbook.modPopup == "trailers" && this.data_handbook.mod == "update") {
      this.trailers.name = this.data_handbook.info.name;
      this.trailers.counterparties = this.data_handbook.info.contractor_name;
      this.trailers.counterparties_id = this.data_handbook.info.contractor_id;
      this.trailers.drivers = this.data_handbook.info.driver_name;
      this.trailers.drivers_id = this.data_handbook.info.driver_id;
    }
    if (this.data_handbook.modPopup == "drivers" && this.data_handbook.mod == "update") {
      this.drivers.name = this.data_handbook.info.name;
      this.drivers.phone = this.data_handbook.info.phone;
      this.drivers.comment = this.data_handbook.info.comment;
      this.drivers.counterparties = this.data_handbook.info.contractor_name;
      this.drivers.counterparties_id = this.data_handbook.info.contractor_id;
      this.drivers.car = this.data_handbook.info.truck_name;
      this.drivers.car_id = this.data_handbook.info.truck_id;
      this.drivers.trailer = this.data_handbook.info.trailer;
      this.drivers.trailer_id = this.data_handbook.info.trailer_id;
    }
    if (
      this.data_handbook.modPopup == "templatesUnloading" &&
      this.data_handbook.mod == "update"
    ) {
      this.templates.addressClearanceOut = this.data_handbook.info.customs_address_to;
      this.templates.addressUnloading = this.data_handbook.info.unloading_address;
      this.templates.unloadingPoint = this.data_handbook.info.fd_name;
      this.templates.unloadingPoint_id = this.data_handbook.info.fd_id;
      this.templates.unloadingPoint_img = this.data_handbook.info.country_code
        ? this.data_handbook.info.country_code
        : "";
    }
    if (
      this.data_handbook.modPopup == "templatesDownload" &&
      this.data_handbook.mod == "update"
    ) {
      this.templates.addressClearanceIn = this.data_handbook.info.customs_address_from;
      this.templates.addressDownload = this.data_handbook.info.loading_address;
      this.templates.downloadPoint = this.data_handbook.info.poo_name;
      this.templates.downloadPoint_id = this.data_handbook.info.poo_id;
      this.templates.downloadPoint_img = this.data_handbook.info.country_code
        ? this.data_handbook.info.country_code
        : "";
      this.templates.cargo = this.data_handbook.info.cargo;
    }
  },

  methods: {
    autocompleteResult(data) {
      if (this.data_handbook.modPopup == "car") {
        this.car[data.input] = data.name;
        this.car[data.input + "_id"] = data.id;
      }
      if (this.data_handbook.modPopup == "trailers") {
        this.trailers[data.input] = data.name;
        this.trailers[data.input + "_id"] = data.id;
      }
      if (this.data_handbook.modPopup == "drivers") {
        this.drivers[data.input] = data.name;
        this.drivers[data.input + "_id"] = data.id;
      }
      if (this.data_handbook.modPopup == "clients") {
        this.fields[data.input] = data.name;
        this.fields[data.input + "_id"] = data.id;
        if (data.img) {
          this.fields.cityImg = data.img
        }
      }
      if (
        this.data_handbook.modPopup == "templatesUnloading" ||
        this.data_handbook.modPopup == "templatesDownload"
      ) {
        this.templates[data.input] = data.name;
        this.templates[data.input + "_id"] = data.id;
      }
    },

    async templateDelete() {
      if (this.templateDeleteBtn) {
        if (this.data_handbook.modPopup == "templatesUnloading") {
          await this.$store.dispatch(
            "deleteTemplateUnloading",
            this.data_handbook.info.id
          );
          this.$emit("closePopup");
        }
        if (this.data_handbook.modPopup == "templatesDownload") {
          await this.$store.dispatch(
            "deleteTemplateLoading",
            this.data_handbook.info.id
          );
          this.$emit("closePopup");
        }
        this.templateDeleteBtn = false;
      } else {
        this.templateDeleteBtn = true;
      }
    },

    async save() {
      if (this.data_handbook.modPopup == "car") {
        var data = {
          name: this.car.name || "",
          gov_number: this.car.gov_number || "",
          year_manufacture: this.car.year_manufacture || "",
          contractor_id: this.car.counterparties_id || "",
          driver_id: this.car.drivers_id || "",
          trailer_id: this.car.trailer_id || "",
        };
        if (this.data_handbook.mod == "update") {
          data.id = this.data_handbook.info.id;
          await this.$store.dispatch("updateCar", data);
        } else {
          await this.$store.dispatch("addCar", data);
          this.$emit("closePopupCar", this.car.name);
        }
        this.$emit("closePopup");
      }

      if (this.data_handbook.modPopup == "trailers") {
        var data = {
          name: this.trailers.name || "",
          contractor_id: this.trailers.counterparties_id || "",
          driver_id: this.trailers.drivers_id || "",
        };
        if (this.data_handbook.mod == "update") {
          data.id = this.data_handbook.info.id;
          await this.$store.dispatch("updateTrailers", data);
        } else {
          await this.$store.dispatch("addTrailers", data);
        }
        this.$emit("closePopup");
      }

      if (this.data_handbook.modPopup == "drivers") {
        var data = {
          name: this.drivers.name || "",
          phone: this.drivers.phone || "",
          comment: this.drivers.comment || "",
          contractor_id: this.drivers.counterparties_id || "",
          truck_id: this.drivers.car_id || "",
          trailer_id: this.drivers.trailer_id || "",
        };
        if (this.drivers.name == "" || this.drivers.phone == "") {
          this.emty = true;
        } else if (this.data_handbook.mod == "update") {
          data.id = this.data_handbook.info.id;
          await this.$store.dispatch("updateDrivers", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addDrivers", data);
          this.$emit("closePopup");
        }
      }

      if (this.data_handbook.modPopup == "contractors") {
        var data = {
          name: this.counterparties.name || "",
          entity_physical_person: this.counterparties.people || "",
          is_main_contractor: this.data_handbook.info.is_main_contractor || "",
          is_carrier: this.data_handbook.info.is_carrier || "",
          main_contractor: this.data_handbook.info.main_contractor || "",
          client_id: this.data_handbook.info.client_id || "",
        };
        if (this.data_handbook.mod == "update") {
          // data.id = this.data_handbook.info.id
          // await this.$store.dispatch('updateDrivers', data)
          // this.$emit('closePopup')
        } else {
          await this.$store.dispatch("createContractors", data);
          this.$emit("closePopup");
        }
      }

      if (this.data_handbook.modPopup == "templatesUnloading") {
        var data = {
          customs_address_to: this.templates.addressClearanceOut || "",
          unloading_address: this.templates.addressUnloading || "",
          fd_id: this.templates.unloadingPoint_id || "",
          client_id: this.data_handbook.idClient,
        };
        if (this.data_handbook.mod == "update") {
          data.id = this.data_handbook.info.id;
          await this.$store.dispatch("updateTemplateUnloading", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addTemplateUnloading", data);
          this.$emit("closePopup");
        }
      }
      if (this.data_handbook.modPopup == "templatesDownload") {
        var data = {
          customs_address_from: this.templates.addressClearanceIn || "",
          loading_address: this.templates.addressDownload || "",
          poo_id: this.templates.downloadPoint_id || "",
          cargo: this.templates.cargo || "",
          client_id: this.data_handbook.idClient,
        };
        if (this.data_handbook.mod == "update") {
          data.id = this.data_handbook.info.id;
          await this.$store.dispatch("updateTemplateLoading", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addTemplateLoading", data);
          this.$emit("closePopup");
        }
      }


      if (this.data_handbook.modPopup == "clients") {
        function boolean(val) {
          return (val == false) ? 0 : 1
        }

        var data = {
          name: this.fields.name || '',
          full_name: this.fields.nameFull || '',
          source_group: this.fields.clientsSource_id,
          dop_priznak: this.fields.additionalSign || '',
          specialControl: boolean(this.fields.specialControl),
          blackList: boolean(this.fields.blacklist),
          city: this.fields.city_id || '',
          // this.fields.address = this.clientsId
          // this.fields.site = this.clientsId
          customer_manager: this.fields.manager_id || '',
          doc_support_manager_id: this.fields.documentManager_id || '',

          expeditor: boolean(this.fields.type.forwarder),
          proizvoditel: boolean(this.fields.type.producer),
          avtosalon: boolean(this.fields.type.motorShow),
          broker: boolean(this.fields.type.broker),

          import: boolean(this.fields.direction.imports),
          export: boolean(this.fields.direction.export),
          tranzit: boolean(this.fields.direction.transit),
          vnutrennie: boolean(this.fields.direction.internal),
          notWork: boolean(this.fields.direction.doNotCarry),

          fraht: boolean(this.fields.service.freight),
          avto: boolean(this.fields.service.auto),
          gd: boolean(this.fields.service.railway),
          avia: boolean(this.fields.service.aviation),
          lcl: boolean(this.fields.service.lcl),
          teo: boolean(this.fields.service.teo),
          perevalka: boolean(this.fields.service.transshipment),
          tamOchistka: boolean(this.fields.service.customsCleaning),

          cargo: this.fields.loads
        }
        var create = await this.$store.dispatch('createClients', data)
        this.$emit("closePopup");
      }
    },
  },

  components: {

  },
};
</script>