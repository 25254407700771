<template>
  <div class="pop-up__contact">
    <div class="pop-up__contact__icon" :class="slide ? 'active' : ''">
      <div class="icon icon-arrow-down" v-if="hidden"></div>
      <a @click.prevent="hidden ? slide = !slide : ''">
        <div class="icon" :class="active.icon_class"></div>
      </a>
    </div>
    <ul class="pop-up__contact__ul" v-if="slide">
      <li
        v-for="(infoRes, index) in info"
        :key="index"
      >
        <a @click.prevent="slide = !slide, active = infoRes, input = ''">
          <div class="icon" :class="infoRes.icon_class"></div>
        </a>
      </li>
    </ul>
    <div :class="active.input_class">
      <input
        :type="active.inputType"
        autocomplete="off"
        :placeholder="active.placeholder" 
        v-model="input"
        v-on:change="changeInput"
        :onkeyup="active.inputType == 'tel' ? input = input.replace(/[^\d]/g,'') : ''"
      />
      <a class="form__close" v-if="input" @click.prevent="input = '', changeInput"></a>
    </div>
  </div>
</template>

<style >
  .icon-arrow-down{
    position: absolute;
    right: 5px;
    top: -5px;
    z-index: -1;
  }
</style>


<script>
export default {
  props: ['data_contact', 'index', 'hidden'],

  data:() => ({
    active: {
      input_class: 'pop-up__contact__phone',
      icon_class: 'icon-phone-new',
      id: '1',
      tech_name: 'phone',
      type: 'phone-urban',
      inputType: 'tel',
      placeholder: '380 (00) - 000 - 00 - 00'
    },
    info: [],
    slide: false,
    input: '',
  }),



  async created() {
    //edit type contacts
    var res = await this.$store.dispatch('getContactsType')
    this.info = res.data.list

    var tel = 'tel'
    var telClass = 'pop-up__contact__phone'
    var email = 'email'
    var other = 'text'
    var otherClass = 'pop-up__contact__mail'
    this.info.forEach(function(item, index){
      if(item.tech_name == 'phone'){
        item.tech_name = 'phone'
        item.icon_class = 'icon-phone-new'
        item.inputType = tel
        item.placeholder = '380 (00) - 000 - 00 - 00'
        item.input_class = telClass
      } else if(item.tech_name == 'fax'){
        item.tech_name = 'fax'
        item.icon_class = 'icon-phone'
        item.inputType = tel
        item.placeholder = '380 (00) - 000 - 00 - 00'
        item.input_class = telClass
      } else if(item.tech_name == 'email'){
        item.tech_name = 'email'
        item.icon_class = 'icon-mail'
        item.inputType = email
        item.placeholder = '@mail.com'
        item.input_class = otherClass
      }else if(item.tech_name == 'skype'){
        item.tech_name = 'skype'
        item.icon_class = 'icon-skype'
        item.inputType = other
        item.placeholder = 'skype'
        item.input_class = otherClass
      }else if(item.tech_name == 'other'){
        item.tech_name = 'other'
        item.icon_class = 'icon-other'
        item.inputType = other
        item.placeholder = ''
        item.input_class = otherClass
      }else if(item.tech_name == 'fb'){
        item.tech_name = 'fb'
        item.icon_class = 'icon-fb'
        item.inputType = other
        item.placeholder = ''
        item.input_class = otherClass
      }
    });

    

    this.input = this.data_contact.val
    var app = this
    this.info.forEach(function(item, index){
      if(item.tech_name == app.data_contact.tech_name){
        app.active = item
      }
    });
    
    var input = this.input
    var type = this.active.id
    var index = this.index
    var tach = this.active.tech_name
    this.$emit('data_change', {value: input, type, index, tach})
  },
  methods: {

    changeInput(){
      var input = this.input
      var type = this.active.id
      var index = this.index
      var tach = this.active.tech_name
      
      this.$emit('data_change', {value: input, type, index, tach})
    }
  },
  
}
</script>