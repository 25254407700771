<template>
  <transition name="fade">
    <div>
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up pop-up--delete">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2 v-if="info.mod == 'update'">
          {{ $store.state.langText.view_contact_person }}
        </h2>
        <h2 v-else>{{ $store.state.langText.creating_contact_person }}</h2>
        <div style="
            max-height: 80vh;
            overflow: auto;
            padding: 30px 10px 0;
            margin-top: -20px;
          ">
          
          <div v-if="info.mod == 'create' || (dataPerson[info.index] && dataPerson[info.index].name)">
            <div class="form__input">
              <input type="text" v-model="name" />
              <span>{{ $store.state.langText.full_name_cont }}</span>
            </div>
            <div class="form__input">
              <input type="text" v-model="post" />
              <span>{{ $store.state.langText.job_title }}</span>
            </div>
            <div class="form__input">
              <input type="text" id="card-number" v-model="card" v-mask="['####-######-#####', '####-####-####-####']"
                style="text-transform: uppercase" />
              <span>{{ $store.state.langText.bank_card }}</span>
            </div>
          </div>

          <ul>
            <li v-for="(item, index) in listContact" :key="index" :data-id="item.contact_id">
              <div class="contact__icon" @click.prevent="openPopup(index)">
                <i class="icon" v-if="
                  $store.state.UniqueID == 'SoftProCrmAppRioTransTest' ||
                  $store.state.UniqueID == 'SoftProCrmAppRioTrans'
                " :class="item.tech_name == 'phone'
                      ? 'icon-phone-new'
                      : item.tech_name == 'fax'
                        ? 'icon-phone'
                        : item.tech_name == 'email'
                          ? 'icon-mail'
                          : item.tech_name == 'skype'
                            ? 'icon-skype'
                            : item.tech_name == 'fb'
                              ? 'icon-fb'
                              : 'icon-other'
                    "></i>
                <i class="icon" v-else :class="item.tech_name == 'phone'
                    ? 'icon-phone-new'
                    : item.tech_name == 'fax'
                      ? 'icon-phone'
                      : item.tech_name == 'email'
                        ? 'icon-mail'
                        : item.tech_name == 'skype'
                          ? 'icon-skype'
                          : 'icon-other'
                  "></i>
                <p>{{ item.val }}</p>
              </div>
              <div class="decor-line"></div>
            </li>
          </ul>

          <Contact v-for="(item, index) in listNew" :data_contact="item" :index="index" :key="item.id" :hidden="hidden"
          @data_change="changeInput" />
          <p style="
              font-size: 13px;
              color: #eb5757;
              margin-bottom: 15px;
              text-align: center;
            " v-if="error">
            {{ $store.state.langText.check_the_data }}
          </p>

          <div class="pop-up__btn" v-if="hidden">
            <a class="btn btn-transparent" @click.prevent="addContacts">
              {{ $store.state.langText.add }}
              <div class="icon-plus"></div>
            </a>
            <a class="btn btn-primary" @click.prevent="saveContact">{{
              $store.state.langText.save2
              }}</a>
          </div>

          <ContactPopup v-if="contact_popup" :hidden="hidden" :modOne="false" :dataAll="dataPerson[info.index]"
            :mod="info.modPerson" :id="id" :data_contact_popup="contact_popupData" @closeContact="ContactPopup()" />
        </div>
      </div>
    </div>
  </transition>
</template>




<script>
import ContactPopup from "@/components/contacts/ContactPopup";
import Contact from "@/components/contacts/Contact";
import { TheMask } from 'vue-the-mask'

export default {
  props: ["info", "dataPerson", "id", "hidden"],

  data: () => ({
    name: "",
    post: "",
    card: "",
    contact_popup: false,
    contact_popupData: "",

    listContact: [],

    listNew: [],

    error: false,
    newIdPerson: "",
  }),

  watch: {
    dataPerson: function () {
      if (this.info.mod == "update") {
        const personData = this.dataPerson[this.info.index];
        if (personData) {
          this.listContact = personData.contacts;
        }
      }
    },
  },

  created() {
    if (this.info.mod == "update") {
      const personData = this.dataPerson[this.info.index];
      if (personData) {
        this.name = personData.name;
        this.post = personData.position;
        this.card = personData.card;
        this.listContact = personData.contacts;
      }
    }
  },

  methods: {
    openPopup(index) {
      if (this.hidden) {
        const personData = this.dataPerson[this.info.index];
        if (personData && personData.contacts[index]) {
          this.contact_popupData = personData.contacts[index];
        }
        this.contact_popup = true;
      }
    },
    ContactPopup() {
      this.contact_popup = false;
      this.$emit("update");
    },
    changeInput(data) {

      this.listNew[data.index] = [];
      this.listNew[data.index].val = data.value;
      this.listNew[data.index].type_id = data.type;
      this.listNew[data.index].id = data.index;
      this.listNew[data.index].tech_name = data.tach;
    },

    async saveContact() {
      var dataPerson = {
        name: this.name,
        position: this.post,
        card: this.card,
        client_id: this.id,
        owner_type: this.info.modPerson,
      };

      if (this.info.mod == "update") {
        if (this.name) {
          dataPerson.id = this.dataPerson[this.info.index] ? this.dataPerson[this.info.index].id : 0;
          var res = await this.$store.dispatch(
            "updateContactsPerson",
            dataPerson
          );
        }
        if (this.listNew.length == 0) {
          this.$emit("closeContact");
        }

        var numb = 0;
        var app = this;
        this.listNew.forEach(async function (item, index) {
          var data = {
            client_id: app.id,
            type: +item.type_id,
            value: item.val,
            owner_type: app.info.modPerson,
          };
          if (app.name) {
            data.person_id = app.dataPerson[app.info.index].id;
          }
          var res = await app.$store.dispatch("addContacts", data);
          if (res.data.status == "error") {
            numb++;
            app.error = true;
          } else {
            app.listNew.splice(index, 1);
            if (app.listNew.length == 0 && numb == 0) {
              app.error = false;
              app.$emit("closeContact");
            } else {
              app.$emit("update");
            }
          }
        });
        numb = 0;
      } else {
        if (this.name) {
          if (this.error) {
            dataPerson.id = this.newIdPerson;
            var person = await this.$store.dispatch(
              "updateContactsPerson",
              dataPerson
            );
          } else {
            var person = await this.$store.dispatch(
              "addContactsPerson",
              dataPerson
            );
            this.newIdPerson = person.data.id;
          }
        }
        var numb = 0;
        if (this.listNew.length !== 0) {
          var app = this;
          this.listNew.forEach(async function (item, index) {
            var data = {
              client_id: app.id,
              type: +item.type_id,
              value: item.val,
              owner_type: app.info.modPerson,
            };
            if (person) {
              data.person_id = person.data.id;
            }
            if (app.error) {
              data.person_id = app.newIdPerson;
            }
            var res = await app.$store.dispatch("addContacts", data);

            if (res.data.status == "error") {
              numb++;
              app.error = true;
            } else {
              app.listContact.push(app.listNew[index]);
              app.listNew.splice(index, 1);
              if (app.listNew.length == 0 && numb == 0) {
                app.error = false;
                app.$emit("closeContact");
              } else {
                app.$emit("update");
              }
            }
          });
          numb = 0;
        } else {
          this.$emit("closeContact");
        }
      }
    },

    addContacts() {
      this.listNew.push({ type_id: "1", val: "", id: this.listNew.length });
    },
  },

  computed: {
    cardMask() {
      // Условная логика для выбора маски
      return this.card.length > 15
        ? "####-######-#####"
        : "####-####-####-####"; // 16 или 15 символов
    },
  },

  components: {
    ContactPopup,
    Contact,
    TheMask
  },
};
</script>