import { createStore } from 'vuex';
import axios from 'axios';
import auth from './auth';
import clients from './clients';
import agreements from './agreements';
import calendar from './calendar';
import quotations from './quotations';
import contractors from './contractors';
import currentUser from './currentUser';
import contacts from './contacts';
import city from './city';
import team from './team';
import legalPerson from './legalPerson';
import services from './services';
import carType from './carType';
import chat from './chat';
import notifications from './notifications';
import template from './template';
import lists from './lists';
import leads from './leads';
import files from './files';
import tasks from './tasks';
// import { inject } from 'vue';



const store = createStore({
  state() {
    return {
      lang: localStorage.getItem('lang') || 'ru',
      langText: {},
      initialized: false,
    };
  },
  actions: {
    async initializeApp({ commit, state, dispatch }) {
        // console.log(root);
        
      if (!state.initialized) {
        state.initialized = true;
        const res = await axios.get('/manifest.json');

        state.UniqueID = res.data.UniqueID;
        state.currencyName = res.data.currencyName;
        state.services_tied_containers = res.data.services_tied_containers;

        axios.defaults.headers.common['Auth-Key'] = res.data.api;
        axios.defaults.baseURL = res.data.url;
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        const token = localStorage.getItem('token');
        if (token) {
          axios.defaults.headers.common['User-Auth-Key'] = token;
        } else {
          delete axios.defaults.headers.common['User-Auth-Key'];
        }
        

        const langRes = await axios.get(`/tech/get_translations/${localStorage.getItem('lang') || 'ru'}.json`);
        state.langText = langRes.data;

        if (localStorage.getItem('token')) {
          await dispatch('getCurrentUser');
        }


      }
    },

    async getLang({ commit }, lang) {
      var result;
      try {
        return await axios
          .get('/tech/get_translations/' + lang + '.json', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
  modules: {
    auth,
    agreements,
    clients,
    quotations,
    calendar,
    contractors,
    currentUser,
    contacts,
    city,
    team,
    legalPerson,
    carType,
    services,
    chat,
    notifications,
    template,
    lists,
    leads,
    files,
    tasks
  },
});

export default store;
