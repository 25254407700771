<template>
  <div class="other" :class="{'menu-open': $root.overflowApp.flag}" >
    <MenuCustum @click="isOpen = !isOpen" :data_is_open="isOpen" />
    <TitleCustom :data_is_active="isOpen" @update-data="isOpen = $event" />


    <router-view v-slot="{ Component }">
      <transition :name="transitionName" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>

    <Toolbar />
    <audio controls="controls" style="display: none" src="https://crm-core.soft-pro.com/assets/new_bell.mp3" id="myAudioCall"></audio>
  </div>
</template>


<style >
  .other{
    overflow: hidden;
    width: 100vw;
  }
  .slide-right-enter-active, .slide-right-leave-active {
    transition: all .5s;
    width: 100%;
    z-index: 9;
  }
  .slide-right-enter, .slide-right-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  .slide-left-enter-active, .slide-left-leave-active {
    transition: all .5s;
    width: 100%;
  }
  .slide-left-enter, .slide-left-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>



<script>
import MenuCustum from '@/components/app/Menu'
import TitleCustom from '@/components/app/Title'
import Toolbar from '@/components/app/Toolbar'
export default {
  name: 'home-layout',
  data: () => ({
    isOpen: false,
    transitionName: '',
    setinerval: '',
  }),




  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },

  async created() {

    
  },




  async mounted() {


    var date = Math.round(new Date().getTime() / 1000)
    this.setinerval = window.setInterval(async () => {
      var data = {
        action: 'main',
        date_from: date
      }

      var notification = await this.$store.dispatch('getNatification', data)
      date = Math.round(new Date().getTime() / 1000);
      if(notification){
        if(notification.data.has_new_messages){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationMess', true);
        }
        if(notification.data.has_new_orders){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationOrder', true);
        }
        if(notification.data.has_new_quotes){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationQuotes', true);
        } 
        if(notification.data.has_new_leads){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationLeads', true);
        }
      }
      
    },3000);
    


    
  },
  beforeUnmount() {
    clearInterval(this.setinerval)
    window.crmUserID = ''
  },




  components: {
    MenuCustum, TitleCustom, Toolbar
  }
}
</script>