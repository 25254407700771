import { createApp } from 'vue';
import { required, sameAs, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { createMetaManager } from 'vue-meta';
import titlePlugin from '@/plugins/meta.js';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import dateFilter from '@/filters/date.filters';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import AutoReloadModule from '@/plugins/AutoReload';
import TheMask from 'vue-the-mask'
import Axios from 'axios';
import * as functions_app from '@/plugins/functions_app';


//Компаненты
import Autocomplete from '@/components/app/Autocomplete.vue';
import Message from "@/components/app/Message";
import DataInput from "@/components/app/DataInput";


async function initializeApp() {
  await store.dispatch('initializeApp');
}

async function createdApp() {


  // Создайте приложение
  const app = createApp(App);


  //Компаненты
  app.component('Autocomplete', Autocomplete);
  app.component('Message', Message);
  app.component('DataInput', DataInput);

  // Подключите глобальные функции
  Object.entries(functions_app).forEach(([key, value]) => {
    app.config.globalProperties[`$${key}`] = value;
  });


  // Подключите Axios как глобальное свойство
  app.config.globalProperties.$http = Axios;
  // Перехватчик ответов. Если 403 - не тот User-Auth-Key, то выходим на страницу логина
  Axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 403) {
        localStorage.removeItem('token')
        delete Axios.defaults.headers.common['User-Auth-Key']
        router.push('/login');
      }
      return Promise.reject(error);
    }
  );


  // Глобальная функция
  app.config.globalProperties.Globalfilters = function (data) {
    console.log('глобальная функция. Вызывайте this.Globalfilters');
  };

  app.config.globalProperties.$filters = {
    date: dateFilter,
  };

  // Подключите плагины
  app.use(titlePlugin);


  app.use(VCalendar, {
    componentPrefix: 'v',
  });

  app.use(TheMask);
  app.config.globalProperties.$vuelidate = useVuelidate;

  // Создание менеджера мета-данных
  const metaManager = createMetaManager();

  // Использование менеджера мета-данных
  app.use(metaManager);

  app.use(AutoReloadModule, {
    config: {
      Enabled: true,
      CheckInterval: 60,
    },
    router: router,
    vm: app,
  });

  // Подключите маршрутизатор и хранилище
  app.use(router);
  app.use(store);

  // Добавьте состояние приложения
  app.mixin({
    data() {
      return {
        lang: localStorage.getItem('lang') || 'ru',
        quote_id: '',
        dataFilling: [],
        doubleClick: {
          date: '',
          click: '0'
        },
        titleName: '',
        title: [{ title: '', info: '' }],
        data: {
          isAuto: true,
          isFraght: false,
          order: {
            info: '',
            customer: '',
            carrier: ''
          },
          client: '',
          contractors: {
            info: '',
            detail: {}
          },
          leads: '',
        },
        overflowApp: {
          flag: false
        },
        status: {
          client: '',
          order: '',
          quotation: '',
          lead: ''
        },
        type: '',
        old_list: '',
        typeMBL: '',
      };
    },
  });


  await initializeApp();



  app.mount('#app');

}
createdApp();