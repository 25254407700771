import axios from 'axios'

export default {
  actions: {

    async getContractors({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/contractors/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getContractorsId({commit}, idContractors) {
      var result;
      try {
        return await axios
          .get("/api/contractors/get/" + idContractors + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },






    async postFiltersContractors({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/contractors/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateContractors({commit}, updateContractors) {
      var result;
      try {
        return await axios
          ('/api/contractors/update/', {
            
            method: 'POST',
            data: updateContractors
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async createContractors({commit}, createContractors) {
      var result;
      try {
        return await axios
          ('/api/contractors/add/', {
            
            method: 'POST',
            data: createContractors
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            // console.log(err)
            return err
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },









    async getContractorsGroup({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/contractors_groups/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersContractorsGroup({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/contractors_groups/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  }
}