<template>
  <div>
    <transition name="fade">
      <div class="container" v-if="!filter.flag">

        <!-- agreements end quotations -->
        <form class="form form-filter" v-if="filter.mod == 'agreements' || filter.mod == 'quotations' || filter.mod == 'calendar'">
          <h2 class="edit">{{$store.state.langText.selected_filters}}</h2>
          <div class="decor-line"></div>
          <div class="form__checkbox" v-if="filter.mod == 'agreements'" style="margin-top: 20px;display: inline-block;margin-right:10px">
            <label>
              <input type="checkbox"
                v-model="filters.in_work"
              >
              <span v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'" style="font-size: 14px !important;">{{$store.state.langText.active_orders}}</span>
              <span v-else style="font-size: 14px !important;">{{$store.state.langText.active_orders}}</span>
            </label>
          </div>

          <div class="form__checkbox" style="margin-top: 20px;display: inline-block;">
            <label>
              <input type="checkbox"
                v-model="filters.only_mine"
              >
              <span style="font-size: 14px !important;">{{$store.state.langText.my}}</span>
            </label>
          </div>

          <div class="decor-line" v-if="!filters.in_work"></div>
          <FiltersAccordion v-if="!filters.in_work" :info="{data: status, name: 'status'}" @data="FiltersAccordion" />
          <div class="decor-line" style="margin-bottom: 15px"></div>

          <Autocomplete
            :data_parent="{
              name: $store.state.langText.client,
              mod: 'clients',
              key: 'client',
              input: filters.client,
              inputId: filters.client_id,
            }"
            @result="autocompleteResult"
          />


          <div class="form__row form__row-50" v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'">
            <div class="form__input">
              <select @change="filters.carrier_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.carrier">
                <option value="main_carrier_all">{{$store.state.langText.everywhere}}</option>
                <option value="main_carrier">{{$store.state.langText.main_carrier}}</option>
                <option value="main_carrier2">{{$store.state.langText.main_carrier2}}</option>
              </select>
              <span>{{$store.state.langText.carrier}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <Autocomplete 
              :data_parent="{
                name: '', 
                filter: 'is_main', 
                mod: 'contractors', 
                key: 'main_carrier', 
                input: filters.main_carrier, 
                inputId: filters.main_carrier_id}" 
              @result="autocompleteResult" 
            />
          </div>


          <div class="form__row form__row-50">
            <div class="form__input">
              <select @change="filters.employee_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.employee">
                <option value="team_user">{{$store.state.langText.everywhere}}</option>
                <option value="creator">{{$store.state.langText.creator}}</option>
                <option value="manager">{{$store.state.langText.manager}}</option>
                <option value="op_manager">{{$store.state.langText.operations_manager}}</option>
                <option value="assistant">{{$store.state.langText.assistant}}</option>
                <option 
                  v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'"
                  value="head_of">{{$store.state.langText.head_of}}
                </option>
                <option 
                  v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'"
                  value="doc_support_manager">{{$store.state.langText.doc_support_manager}}
                </option>
                <option 
                  v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'"
                  value="other_manager">{{$store.state.langText.other}}
                </option>
              </select>
              <span>{{$store.state.langText.team_user}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <Autocomplete
              :data_parent="{
                name: '',
                mod: 'team',
                key: 'manager',
                input: filters.manager,
                inputId: filters.manager_id,
              }"
              @result="autocompleteResult"
            />
          </div>


          <div class="form__row form__row-50">
            <div class="form__input">
              <select @change="filters.cityAll_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.cityAll">
                <option value="pol">{{$store.state.langText.loading_port}}</option>
                <option value="pod">{{$store.state.langText.port_of_unloading}}</option>
              </select>
              <span>{{$store.state.langText.port}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <Autocomplete
              :data_parent="{
                name: '', 
                mod: 'city', 
                key: 'city', 
                input: filters.city, 
                inputId: filters.city_id}" 
              @result="autocompleteResult" 
            />
          </div>


          <div class="form__row form__row-50">
            <div class="form__input">
              <select @change="filters.countryAll_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.countryAll">
                <option value="pol_country">{{$store.state.langText.loading_country}}</option>
                <option value="pod_country">{{$store.state.langText.country_of_unloading}}</option>
              </select>
              <span>{{$store.state.langText.countries}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <Autocomplete
              :data_parent="{
                name: '', 
                mod: 'countries', 
                key: 'country', 
                input: filters.country, 
                inputId: filters.country_id}" 
              @result="autocompleteResult" 
            />
          </div>

          <div class="form__row form__row-50" v-if="filter.mod !== 'calendar'">
            <div class="form__input">
              <select @change="filters.date_select_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.date_select">
                <option value="date">{{$store.state.langText.creation}}</option>
                <option value="date_fin">{{$store.state.langText.completion}}</option>
                <option value="date_eta">{{$store.state.langText.eta}}</option>
                <option value="date_of_load" v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'">{{$store.state.langText.download_plan}}</option>
                <option value="shipment_date" v-if="$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || $store.state.UniqueID == 'SoftProCrmAppRioTrans'">{{$store.state.langText.loading_fact}}</option>
              </select>
              <span>{{$store.state.langText.date_period}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <DataInput
              :data_date="{
                data: filters.date,
                input: 'date',
                name: '',
                mod: 'is-range'
              }"
              @resultData="dataInput"
            />
          </div>
          

          <div  v-if="filter.mod !== 'calendar'">
            <div style="margin-top: -15px">
              <FiltersAccordion :info="{data: view, name: 'view'}" @data="FiltersAccordion" />
            </div>
            <div class="decor-line"></div>

            <FiltersAccordion :info="{data: type, name: 'type'}" @data="FiltersAccordion" />
            <div class="decor-line" style="margin-bottom: 15px"></div>

          </div>


        </form>



        <!-- clients -->
        <form class="form form-filter" v-if="filter.mod == 'clients'">
          <h2 class="edit">{{$store.state.langText.select_filters}}</h2>
          <div class="decor-line"></div>
          
          <FiltersAccordion :info="{data: status, name: 'status'}" @data="FiltersAccordion" />
          <div class="decor-line"></div>

          <FiltersAccordion :info="{data: typeCompany, name: 'typeCompany'}" @data="FiltersAccordion" />
          <div class="decor-line" style="margin-bottom:15px"></div>

  
          <Autocomplete
            :data_parent="{
              name: $store.state.langText.manager,
              mod: 'team',
              key: 'manager',
              input: filters.manager,
              inputId: filters.manager_id,
            }"
            @result="autocompleteResult"
          />


          <div class="form__row form__row-50">
            <div class="form__input">
              <select @change="filters.date_select_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.date_select">
                <option value="date">{{$store.state.langText.creation}}</option>
                <option value="date_last">{{$store.state.langText.last_contact}}</option>
                <option value="date_next">{{$store.state.langText.next_contact}}</option>
              </select>
              <span>{{$store.state.langText.date_period}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <DataInput
              :data_date="{
                data: filters.date,
                input: 'date',
                name: '',
                mod: 'is-range'
              }"
              @resultData="dataInput"
            />
          </div>


          <div style="margin-top: -15px">
            <FiltersAccordion :info="{data: funnel, name: 'funnel'}" @data="FiltersAccordion" />
            <div class="decor-line"></div>
          </div>


        </form>



         <!-- Contractors -->
        <form class="form form-filter" v-if="filter.mod == 'contractors'">
          <h2 class="edit">{{$store.state.langText.select_filters}}</h2>
          <div class="decor-line"></div>
          
          <FiltersAccordion :info="{data: groupContractors, name: 'groupContractors'}" @data="FiltersAccordion" />
          <div class="decor-line"></div>

          <FiltersAccordion :info="{data: typeContractors, name: 'typeContractors'}" @data="FiltersAccordion" />
          <div class="decor-line"></div>
        </form>



        <!-- city -->
        <form class="form form-filter" style="overflow: auto;max-height: 76vh;" v-if="filter.mod == 'city'">
          <h2 class="edit">{{$store.state.langText.select_filters}}</h2>
          <div class="decor-line" style="margin-bottom:15px"></div>
          
          <Autocomplete
            :data_parent="{
              name: $store.state.langText.countries, 
              mod: 'countries', 
              key: 'country', 
              input: filters.country, 
              inputId: filters.country_id}" 
            @result="autocompleteResult" 
          />
        </form>



        <!-- leads -->
        <form class="form form-filter" v-if="filter.mod == 'leads'">
          <h2 class="edit">{{$store.state.langText.select_filters}}</h2>
          <div class="decor-line"></div>
          
          <FiltersAccordion :info="{data: status, name: 'status'}" @data="FiltersAccordion" />
          <div class="decor-line" style="margin-bottom:15px"></div>

          <Autocomplete
            :data_parent="{
              name: $store.state.langText.source,
              mod: 'clientsSource',
              key: 'source',
              input: filters.source,
              inputId: filters.source_id,
            }"
            @result="autocompleteResult"
          />

          <Autocomplete
            :data_parent="{
              name: $store.state.langText.manager,
              mod: 'team',
              key: 'manager',
              input: filters.manager,
              inputId: filters.manager_id,
            }"
            @result="autocompleteResult"
          />

          <div class="form__row form__row-50">
            <div class="form__input">
              <select @change="filters.date_select_name = $event.target.options[$event.target.selectedIndex].text" v-model="filters.date_select">
                <option value="date">{{$store.state.langText.creation}}</option>
              </select>
              <span>{{$store.state.langText.date_period}}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <DataInput
              :data_date="{
                data: filters.date,
                input: 'date',
                name: '',
                mod: 'is-range'
              }"
              @resultData="dataInput"
            />
          </div>



        </form>



        <div class="block-btn block-btn--color">
          <a class="btn btn-primary" @click.prevent="removeFilters">{{$store.state.langText.cancel}}</a>
          <a class="btn btn-secondary" @click.prevent="sendFilters">{{$store.state.langText.apply}}</a>
        </div>
      </div>
    </transition>
  </div>
</template>



<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>


<script>
import FiltersAccordion from "@/components/app/FiltersAccordion"

export default {
  props: ['filter', 'isFilter', 'filterChange', 'filterSelectContractor'],



  data: () => ({

    //data filters
    filters: {
      in_work: true,
      only_mine: false,
      client: '',
      client_id: '',
      downloadPoint: '',
      unloadingPoint: '',
      unloadingPoint_id: '',
      deliveryDate: '',
      dateAdded: '',
      counterparty: '',
      counterparty_id: '',
      search: '',
      source: '',
      source_id: '',

      employee: 'team_user',
      employee_name: '',
      manager: '',
      manager_id: '',

      countryAll: 'pol_country',
      countryAll_name: '',
      country: '',
      country_id: '',

      cityAll: 'pol',
      cityAll_name: '',
      city: '',
      city_id: '',

      carrier: 'main_carrier_all',
      carrier_name: '',
      main_carrier: '',
      main_carrier_id: '',

      date_select: 'date',
      date_select_name: '',
      date: ''
    },

    status: [],
    view: [],
    type: [],
    funnel: [],
    //contractors
    groupContractors: [],
    typeContractors: [
      {id: 0, name: 'Юр. лицо', checked: false, title: "Тип",},
      {id: 1, name: 'Физ. лицо', checked: false},
    ],
    //clients
    typeCompany: [
      {id: 6, name: 'Без типа компании', checked: false, title: "Тип компании",},
      {id: 1, name: 'Экспедитор', checked: false},
      {id: 2, name: 'Брокер', checked: false},
      {id: 3, name: 'Производитель', checked: false},
      {id: 4, name: 'Трейдер', checked: false},
      {id: 5, name: 'Автосалон', checked: false}
    ],
  }),


  watch: {
    filterChange: function(){
      var app = this
      if(this.filterChange.list){
        app[app.filterChange.key].forEach(function(item, index){
          if(app.filterChange.val == item.id){
            item.checked = false
          }
        })
      } else{
        var key = app.filterChange.key;
        this.filters[key] = ''
        this.filters[key + '_id'] = ''
        //manager
        if(key == 'team_user' || key == 'creator' || key == 'manager' || key == 'op_manager' || key == 'assistant' ||
           key == 'head_of' || key == 'doc_support_manager' || key == 'other_manager'
        ){
          this.filters.manager = ''
          this.filters.manager_id = ''
        }
        //city
        if(key == 'pol' || key == 'pod'){
          this.filters.city = ''
          this.filters.city_id = ''
        }
        //country
        if(key == 'pol_country' || key == 'pod_country'){
          this.filters.country = ''
          this.filters.country_id = ''
        }
        //main_carrier
        if(key == 'main_carrier' || key == 'main_carrier2' || key == 'main_carrier_all'){
          this.filters.main_carrier = ''
          this.filters.main_carrier_id = ''
        }
        //date
        if(key == 'date' || 
          key == 'date_fin' || 
          key == 'date_eta' || 
          key == 'date_of_load' || 
          key == 'shipment_date' || 
          key == 'date_last' || 
          key == 'date_next' 
        ){
          this.filters.date = ''
        }
      }
      this.sendFilters();
    }
  },


  async created() {
    var app = this
    app.createdAll(true)
    window.onpopstate = function(event) {
      app.createdAll(false)
    };
    
  },



  methods: {
    async createdAll(data){
      this.filters.employee_name = this.$store.state.langText.everywhere
      this.filters.cityAll_name = this.$store.state.langText.loading_port
      this.filters.countryAll_name = this.$store.state.langText.loading_country
      this.filters.carrier_name = this.$store.state.langText.everywhere
      this.filters.date_select_name = this.$store.state.langText.creation

      //typeCompany
      this.typeCompany[0].name = this.$store.state.langText.no_company_type
      this.typeCompany[0].title = this.$store.state.langText.company_type
      this.typeCompany[1].name = this.$store.state.langText.expeditor
      this.typeCompany[2].name = this.$store.state.langText.broker
      this.typeCompany[3].name = this.$store.state.langText.proizvoditel
      this.typeCompany[4].name = this.$store.state.langText.treyder
      this.typeCompany[5].name = this.$store.state.langText.avtosalon

      //typeContractors
      this.typeContractors[0].name = this.$store.state.langText.legal_entity
      this.typeContractors[0].title = this.$store.state.langText.type
      this.typeContractors[1].name = this.$store.state.langText.physical_person_min

      function getData(data, title, info){
        info.forEach(function(item, index){
          if(index == 0){
            data.push(item)
            data[index].title = title
            data[index].checked = false
          } else{
            data.push(item)
            data[index].checked = false
          }
        })
      }


      if(this.filter.mod == 'agreements'){

        if(this.$root.status.order){
          getData(this.status, this.$store.state.langText.status, this.$root.status.order)
        } else{
          var res = await this.$store.dispatch('getStatus')
          getData(this.status, this.$store.state.langText.status, res.data.list)
          this.$root.status.order = res.data.list
        }
      }
      if(this.filter.mod == 'quotations'){
        if(this.$root.status.quotation){
          getData(this.status, this.$store.state.langText.status, this.$root.status.quotation)
        } else{
          var res = await this.$store.dispatch('getStatusQ');
          getData(this.status, this.$store.state.langText.status, res.data.list)
          this.$root.status.quotation = res.data.list
        }
      }
      if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
        if(this.$root.type){
          getData(this.type, this.$store.state.langText.type, this.$root.type)
        } else{
          var res = await this.$store.dispatch('getOrdersTypes', {start: 0, limit: 50})
          getData(this.type, this.$store.state.langText.type, res.data.list)
          this.$root.type = res.data.list
        }
        if(this.$root.old_list){
          getData(this.view, this.$store.state.langText.view, this.$root.old_list)
        } else{
          var res = await this.$store.dispatch('orderType')
          getData(this.view, this.$store.state.langText.view, res.data.list)
          this.$root.old_list = res.data.list
        }
      }

      if(this.filter.mod == 'contractors'){
        var res = await this.$store.dispatch('getContractorsGroup', {start: 0, limit: 50})
        getData(this.groupContractors, 'Группа', res.data.list)
      }


      if(this.filter.mod == 'clients'){
        var res = await this.$store.dispatch('getClientsOrganizations')
        getData(this.funnel, 'Органiзацiя', res.data.list)

        var res = await this.$store.dispatch('getClientsStatus')
        getData(this.status, this.$store.state.langText.status, res.data.list)
      }

      if(this.filter.mod == 'leads'){
        if(this.$root.status.lead){
          getData(this.status, this.$store.state.langText.status, this.$root.status.lead)
        } else{
          var res = await this.$store.dispatch('getLeadsStatus')
          getData(this.status, this.$store.state.langText.status, res.data.list)
          this.$root.status.lead = res.data.list
        }
      }





      //reload
      function getSearchParams(k){
        var p={};
        decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) { p[k] = v })
        return k ? p[k] : p;
      }
      function activeFilters(el, key){
        var arr = efe[el].split(',')
        key.forEach(function(item, index){
          var arrItem = item
          arr.forEach(function(item2, index2){
            if(arrItem.id == item2){
              arrItem.checked = true
            }
          })
        })
      }

      if(this.isFilter !== false){
        var efe = getSearchParams()

        if(efe['f[only_mine]']) {
          this.filters.only_mine = 1
        }

        if(efe['f[status]']) {
          activeFilters('f[status]', this.status)
        }
        if(efe['f[client]']) {
          const res = await this.$store.dispatch('getClientsId', efe['f[client]'])
          this.filters.client = res.data.detail.name
          this.filters.client_id = efe['f[client]']
        } else{this.filters.client = '';this.filters.client_id = '';}
        if(efe['f[country]']) {
          const res = await this.$store.dispatch('getCountryId', efe['f[country]'])
          this.filters.country = res.data.detail.name
          this.filters.country_id = efe['f[country]']
        }else{this.filters.country = '';this.filters.country_id = '';}
        if(efe['f[direction]']) {
          activeFilters('f[direction]', this.view)
        }
        if(efe['f[counterparty]']) {
          const res = await this.$store.dispatch('getContractorsId', efe['f[counterparty]'])
          this.filters.counterparty = res.data.detail.name
          this.filters.counterparty_id = efe['f[counterparty]']
        }else{this.filters.counterparty = '';this.filters.counterparty_id = '';}
        if(efe['f[company_type]']) {
          activeFilters('f[company_type]', this.typeCompany)
        }
        if(efe['f[organization]']) {
          activeFilters('f[organization]', this.funnel)
        }
        if(efe['f[group]']) {
          activeFilters('f[group]', this.groupContractors)
        }
        if(efe['f[is_physical_person]']) {
          activeFilters('f[is_physical_person]', this.typeContractors)
        }
        if(efe['f[source]']) {
          const res = await this.$store.dispatch('getClientsSourceId', efe['f[source]'])
          this.filters.source = res.data.detail.name
          this.filters.source_id = efe['f[source]']
        }else{this.filters.source = '';this.filters.source_id = '';}

        //employee
        var employee = '';
        if(efe['f[team_user]']) {
          employee = 'team_user';
          this.filters.employee_name = this.$store.state.langText.everywhere
        }
        if(efe['f[creator]']) {
          employee = 'creator';
          this.filters.employee_name = this.$store.state.langText.creator
        }
        if(efe['f[manager]']) {
          employee = 'manager';
          this.filters.employee_name = this.$store.state.langText.manager
        }
        if(efe['f[op_manager]']) {
          employee = 'op_manager';
          this.filters.employee_name = this.$store.state.langText.operations_manager
        }
        if(efe['f[assistant]']) {
          employee = 'assistant';
          this.filters.employee_name = this.$store.state.langText.assistant
        }
        if(efe['f[head_of]']) {
          employee = 'head_of';
          this.filters.employee_name = this.$store.state.langText.head_of
        }
        if(efe['f[doc_support_manager]']) {
          employee = 'doc_support_manager';
          this.filters.employee_name = this.$store.state.langText.doc_support_manager
        }
        if(efe['f[other_manager]']) {
          employee = 'other_manager';
          this.filters.employee_name = this.$store.state.langText.other
        }

        if(employee){
          this.filters.employee = employee;
          const res = await this.$store.dispatch('getTeamId', efe['f['+ employee +']']);
          this.filters.manager = res.data.detail.name;
          this.filters.manager_id = efe['f['+ employee +']'];
        }else{this.filters.manager = '';this.filters.manager_id = '';}


        //city
        var cityAll = '';
        if(efe['f[pol]']) {
          cityAll = 'pol';
          this.filters.cityAll_name = this.$store.state.langText.loading_point
        }
        if(efe['f[pod]']) {
          cityAll = 'pod';
          this.filters.cityAll_name = this.$store.state.langText.unloading_point
        }

        if(cityAll){
          this.filters.cityAll = cityAll;
          const res = await this.$store.dispatch('getCityId', efe['f['+ cityAll +']']);
          this.filters.city = res.data.detail.name;
          this.filters.city_id = efe['f['+ cityAll +']'];
        }else{this.filters.city = '';this.filters.city_id = '';}


        //country
        var countryAll = '';
        if(efe['f[pol_country]']) {
          countryAll = 'pol_country';
          this.filters.countryAll_name = this.$store.state.langText.loading_country
        }
        if(efe['f[pod_country]']) {
          countryAll = 'pod_country';
          this.filters.countryAll_name = this.$store.state.langText.country_of_unloading
        }

        if(countryAll){
          this.filters.countryAll = countryAll;
          const res = await this.$store.dispatch('getCountryId', efe['f['+ countryAll +']']);
          this.filters.country = res.data.detail.name;
          this.filters.country_id = efe['f['+ countryAll +']'];
        }else{this.filters.country = '';this.filters.country_id = '';}

        //main_carrier
        var carrier = '';
        if(efe['f[main_carrier_all]']) {
          carrier = 'main_carrier_all';
          this.filters.carrier_name = this.$store.state.langText.carrier
        }
        if(efe['f[main_carrier]']) {
          carrier = 'main_carrier';
          this.filters.carrier_name = this.$store.state.langText.main_carrier
        }
        if(efe['f[main_carrier2]']) {
          carrier = 'main_carrier2';
          this.filters.carrier_name = this.$store.state.langText.main_carrier2
        }

        if(carrier){
          this.filters.carrier = carrier;
          const res = await this.$store.dispatch('getContractorsId', efe['f['+ carrier +']']);
          this.filters.main_carrier = res.data.detail.name;
          this.filters.main_carrier_id = efe['f['+ carrier +']'];
        }else{this.filters.main_carrier = '';this.filters.main_carrier_id = '';}

        //date
        function date(date) {
          if (date) {
            var year = date.substr(8, 2);
            var day = date.substr(0, 2);
            var month = date.substr(3, 2);
            var resultDate = day + "." + month + '.' + year;
            return resultDate;
          }
        }
        if(efe['f[date_from]'] || efe['f[date_to]']) {
          this.filters.date_select_name = this.$store.state.langText.creation;
          this.filters.date_select = 'date';
          this.filters.date = date(efe['f[date_from]']) + '-' + date(efe['f[date_to]']);
        }
        if(efe['f[date_fin_from]'] || efe['f[date_fin_to]']) {
          this.filters.date_select_name = this.$store.state.langText.completion;
          this.filters.date_select = 'date_fin';
          this.filters.date = date(efe['f[date_fin_from]']) + '-' + date(efe['f[date_fin_to]']);
        }
        if(efe['f[date_eta_from]'] || efe['f[date_eta_to]']) {
          this.filters.date_select_name = this.$store.state.langText.eta;
          this.filters.date_select = 'date_eta';
          this.filters.date = date(efe['f[date_eta_from]']) + '-' + date(efe['f[date_eta_to]']);
        }
        if(efe['f[date_of_load_from]'] || efe['f[date_of_load_to]']) {
          this.filters.date_select_name = this.$store.state.langText.download_plan;
          this.filters.date_select = 'date_of_load';
          this.filters.date = date(efe['f[date_of_load_from]']) + '-' + date(efe['f[date_of_load_to]']);
        }
        if(efe['f[shipment_date_from]'] || efe['f[shipment_date_to]']) {
          this.filters.date_select_name = this.$store.state.langText.loading_fact;
          this.filters.date_select = 'shipment_date';
          this.filters.date = date(efe['f[shipment_date_from]']) + '-' + date(efe['f[shipment_date_to]']);
        }
        if(efe['f[date_last_from]'] || efe['f[date_last_to]']) {
          this.filters.date_select_name = this.$store.state.langText.last_contact;
          this.filters.date_select = 'date_last';
          this.filters.date = date(efe['f[date_last_from]']) + '-' + date(efe['f[date_last_to]']);
        }
        if(efe['f[date_next_from]'] || efe['f[date_next_to]']) {
          this.filters.date_select_name = this.$store.state.langText.next_contact;
          this.filters.date_select = 'date_next';
          this.filters.date = date(efe['f[date_next_from]']) + '-' + date(efe['f[date_next_to]']);
        }
        if(efe['f[date_from]'] || efe['f[date_to]'] || efe['f[date_fin_from]'] || efe['f[date_fin_to]'] ||
          efe['f[date_eta_from]'] || efe['f[date_eta_to]'] || efe['f[date_of_load_from]'] || efe['f[date_of_load_to]'] || 
          efe['f[shipment_date_from]'] || efe['f[shipment_date_to]'] || efe['f[date_next_from]'] || efe['f[date_next_to]'] ||
          efe['f[date_last_from]'] || efe['f[date_last_to]']
        ){} else{this.filters.date = ''}

        if(data){
          this.sendFilters('load');
        } else{
          this.sendFilters();
        }
      }
    },






    //FiltersAccordion
    FiltersAccordion(data){
      this[data.input] = data.data
    },
    //date
    dataInput(data) {
      this.filters[data.input] = data.date;
    },
    //autocomplete
    autocompleteResult(data) {
      this.filters[data.input] = data.name;
      this.filters[data.input + "_id"] = data.id;
    },
    //removeFilters
    removeFilters(){
      document.querySelectorAll('.button-back')[0].classList.remove("hidden");
      this.$emit('isActiveFilter', false);
      this.$emit('filter');
    },



    //send
    async sendFilters(load){
      if(document.querySelectorAll('.button-back').length > 0){
        document.querySelectorAll('.button-back')[0].classList.remove("hidden");
      }
      //decoder
      function serialize(obj, prefix) {
        var str = [],
          p;
        for (p in obj) {
          if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
              v = obj[p];
            str.push((v !== null && typeof v === "object") ?
              serialize(v, k) :
              encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
        return str.join("&");
      }
      //numbeer
      function number(input){
        var status = null;
        input.forEach(function(item, index){
          if(item.checked){
            if(status == null){
              status = item.id
            } else{
              status = status + ',' + item.id
            }
          }
        });
        return status
      }
      //isActive
      function isActive(key, name, input){
        var status = [];
        input.forEach(function(item, index){
          if(item.checked){
            status.push({list: true, key: key, name: name, valname: item.name, val: item.id})
          }
        });
        return status
      }



      var data = {};
      var dataIsFilter = [];
      var status = number(this.status)
      var statusActive = isActive('status', this.$store.state.langText.status, this.status)
      var type = number(this.type)
      var typeActive = isActive('type', this.$store.state.langText.type, this.type)
      var view = number(this.view)
      var viewActive = isActive('view', this.$store.state.langText.view, this.view)
      var typeCompany = number(this.typeCompany)
      var typeCompanyActive = isActive('typeCompany', this.$store.state.langText.company_type, this.typeCompany)
      var funnel = number(this.funnel)
      var funnelActive = isActive('funnel', this.$store.state.langText.org_id, this.funnel)
      var groupContractors = number(this.groupContractors)
      var groupContractorsActive = isActive('groupContractors', this.$store.state.langText.group, this.groupContractors)
      var typeContractors = number(this.typeContractors)
      var typeContractorsActive = isActive('typeContractors', this.$store.state.langText.type, this.typeContractors)

      //checkbox
      if(status){
        data.status = status
        statusActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }
      if(type){
        data.order_type = type
        typeActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }

      if(view){
        data.direction = view
        viewActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }
      if(typeCompany){
        data.company_type = typeCompany
        typeCompanyActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }
      if(funnel){
        data.organization = funnel
        funnelActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }
      if(groupContractors){
        data.group = groupContractors
        groupContractorsActive.forEach(function(item){
          dataIsFilter.push(item)
        })
      }
      if(typeContractors){
        this.typeContractors.forEach(function(item, index){
          if(item.checked){
            if(item.value == 0){
              data.is_legal_person = 1
            } else{
              data.is_physical_person = 1
            }  
            typeContractorsActive.forEach(function(item){
              dataIsFilter.push(item)
            })
          }
        })
      }

      //input
      if(this.filters.client_id){
        data.client = this.filters.client_id
        dataIsFilter.push({list: false, name: this.$store.state.langText.client, key: 'client', valname: this.filters.client, val: this.filters.client_id})
      }
      if(this.filters.date){
        if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations' || 
          this.filter.mod == 'leads' || this.filter.mod == 'clients'
        ){
          function date(date) {
            if (date) {
              var year = date.substr(6, 2);
              var day = date.substr(0, 2);
              var month = date.substr(3, 2);
              var resultDate = day + "-" + month + '-20' + year;
              return resultDate;
            }
          }
          data[this.filters.date_select + '_from'] = date(this.filters.date.split('-')[0]);
          data[this.filters.date_select + '_to'] = date(this.filters.date.split('-')[1]);
          dataIsFilter.push({list: false, name: this.filters.date_select_name, key: this.filters.date_select, valname: this.filters.date, val: this.filters.date})
        } else{
          
        }
      }
      if(this.filters.main_carrier_id){
        if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
          data[this.filters.carrier] = this.filters.main_carrier_id;
          if(this.filters.carrier == 'main_carrier_all'){
            this.filters.carrier_name = this.$store.state.langText.carrier
          }
          dataIsFilter.push({list: false, name: this.filters.carrier_name, key: this.filters.carrier, valname: this.filters.main_carrier, val: this.filters.main_carrier_id})
        } else{
          data.main_carrier_id = this.filters.main_carrier_id
          dataIsFilter.push({list: false, name: this.$store.state.langText.main_carrier, key: 'counterparty', valname: this.filters.main_carrier, val: this.filters.main_carrier_id})
        }
      }
      if(this.filters.country_id){
        if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
          data[this.filters.countryAll] = this.filters.country_id
          dataIsFilter.push({list: false, name: this.filters.countryAll_name, key: this.filters.countryAll, valname: this.filters.country, val: this.filters.country_id})
        } else{
          data.country = this.filters.country_id
          dataIsFilter.push({list: false, name: this.$store.state.langText.countries, key: 'country', valname: this.filters.country, val: this.filters.country_id})
        }
      }
      if(this.filters.city_id){
        if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
          data[this.filters.cityAll] = this.filters.city_id
          dataIsFilter.push({list: false, name: this.filters.cityAll_name, key: this.filters.cityAll, valname: this.filters.city, val: this.filters.city_id})
        } else{
          data.city = this.filters.city_id
          dataIsFilter.push({list: false, name: this.$store.state.langText.cities, key: 'city', valname: this.filters.city, val: this.filters.city_id})
        }
      }
      if(this.filters.counterparty_id){
        data.counterparty = this.filters.counterparty_id
        dataIsFilter.push({list: false, name: this.$store.state.langText.contractor, key: 'counterparty', valname: this.filters.counterparty, val: this.filters.counterparty_id})
      }
      if(this.filters.source_id){
        data.source = this.filters.source_id
        dataIsFilter.push({list: false, name: this.$store.state.langText.source, key: 'source', valname: this.filters.source, val: this.filters.source_id})
      }
      if(this.filters.manager){
        if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
          data[this.filters.employee] = this.filters.manager_id
          if(this.filters.employee == 'team_user'){
            this.filters.employee_name = this.$store.state.langText.team_user
          }
          dataIsFilter.push({list: false, name: this.filters.employee_name, key: this.filters.employee, valname: this.filters.manager, val: this.filters.manager_id})
        } else{
          data.manager = this.filters.manager_id
          dataIsFilter.push({list: false, name: this.$store.state.langText.manager, key: 'manager', valname: this.filters.manager, val: this.filters.manager_id})
        }
      }

      if(this.filter.mod == 'agreements' || this.filter.mod == 'quotations'){
        if(this.filters.in_work && this.filter.mod == 'agreements'){
          data.in_work = 1
          if(this.$store.state.UniqueID == 'SoftProCrmAppRioTransTest' || this.$store.state.UniqueID == 'SoftProCrmAppRioTrans'){
            dataIsFilter.push({list: false, name: this.$store.state.langText.active_orders, key: 'in_work'})
          } else{
            dataIsFilter.push({list: false, name: this.$store.state.langText.active_orders, key: 'in_work'})
          }
          
        }
        if(this.filters.only_mine){
          data.only_mine = 1
          dataIsFilter.push({list: false, name: this.$store.state.langText.my, key: 'only_mine'})
        }

      } else if(this.filter.mod == 'contractors'){

        if(this.filterSelectContractor){
          if(this.filterSelectContractor.customerFilter){
            data[this.filterSelectContractor.customerFilter] = 1
          }
          if(this.filterSelectContractor.clientId){
            data.client_id = this.filterSelectContractor.clientId
          }
          if(this.filterSelectContractor.idContractor){
            data.contractor_id = this.filterSelectContractor.idContractor
          }
          if(this.filterSelectContractor.idMainContractor){
            data.main_contractor = this.filterSelectContractor.idMainContractor
          }
        }

      } 

      var filter = serialize(data, 'f')

      if(load == 'load'){
        this.$emit('filter', {filter, dataIsFilter, load: true})
      } else{
        this.$emit('filter', {filter, dataIsFilter})
      }
      this.$emit('isActiveFilter', true)
    }

  },
  components: {
    FiltersAccordion
  }
  
}
</script>