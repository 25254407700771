<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2>{{$store.state.langText.contact_persons}}</h2>
        <p class="list-not" v-if="listNot">{{$store.state.langText.no_contact_persons}}</p>
        <ul class="pop-up__list">
          <li
            v-for="(item, index) in contact"
            :key="index"
            :data-id="item.id"
            @click.prevent="$emit('close', {id: item.id, name: item.name, phone: item.phone})"
          >
            <div class="pop-up__list__row">
              {{item.name}} {{item.position ? '('+ item.position +')' : ''}}
            </div>
            <div class="decor-line"></div>
          </li>
        </ul>
        <div style="position: relative; height: 73px">
          <a class="btn btn-transparent" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="openList('create')">{{$store.state.langText.add}}<div class="icon-plus"></div></a>
        </div>
        <ContactsPerson v-if="ContactsPerson.flag" :hidden="hidden" :info="ContactsPerson" :id="data_contact.idClient" @closeContact="closeContact" @close="ContactsPerson.flag = false" @update="update()" /> 
      </div>
    </div>
  </transition>
</template>



<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>


<script>
import ContactsPerson from '@/components/contacts/ContactsPerson'

export default {
  props: ['popup', 'data_contact', 'hidden'],

  data:() => ({
    contact: [],
    quantityList: '',
    ContactsPerson: {flag: false, id: '', index: '', modPerson: 'contractor', mod: 'create'},
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    listNot: false
    
  }),
  async mounted() {
    //base
    var id = this.data_contact.idClient
    var type = this.data_contact.typeClient
    const contact = await this.$store.dispatch('getContactsPersons', {id, type} );
    // this.contact = contact.data.list;

    var app = this
    contact.data.list.forEach(function(item, index){
      if(item.id){
        app.contact.push(item)
      }
    })

    if(this.contact.length == 0){
      this.listNot = true
    }
  },

  methods: {
    update(){
     
    },

    async closeContact(data){
      this.ContactsPerson.flag = false
      this.contact_popup = false
      var id = this.data_contact.idClient
      var type = this.data_contact.typeClient
      var contact = await this.$store.dispatch('getContactsPersons', {id, type} );
      this.contact = contact.data.list;

      // var app = this
      // contact.data.list.forEach(function(item, index){
      //   if(item.id){
      //     app.contact.push(item)
      //   }
      // })

      if(this.contact.length == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },
    openList(mod, index){
      this.ContactsPerson.id = this.data_contact.idClient
      this.ContactsPerson.modPerson = this.data_contact.typeClient
      this.ContactsPerson.flag = !this.ContactsPerson.flag
    }
  },

  components:{
    ContactsPerson
  }

}
</script>