<template>
  <ul class="paginations">
    <li class="paginations__prev" v-if="prev">
      <a @click.prevent="prevList" class="icon icon-arrow-down"></a>
    </li>
    <li
      :class="index == activeClass ? 'active' : ''"
      v-for="(item, index) in items" 
      :key="index"
    >
      <a @click.prevent="numList(item, index)">{{item}}</a>
    </li>
    <li v-if="next" class="paginations__next">
      <a @click.prevent="nextList" class="icon icon-arrow-down"></a>
    </li>
  </ul>
</template>


<style >
  .paginations{
    padding-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }
  .paginations__prev a{
    margin-right: 10px !important;
    transform: rotate(90deg);
  }
  .paginations__next a{
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  .paginations li.active a{
    border-color: #004D9D;
    color: #004D9D;
  }
  .paginations a{
    font-size: 16px;
    font-weight: 400;
    width: 30px;
    height: 30px;
    border: 1px solid #8EB6E3;
    margin-right: 5px;
    color: #8EB6E3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .paginations .icon-arrow-down{
    position: static !important;
  }
</style>


<script>
export default {
  props: ['dataList'],

  data: () => ({
    params: {},
    items: [1, 2, 3],
    next: true,
    prev: false,
    pages: '',
    activeClass: 0,
  }),

  mounted() {
    this.params = this.dataList.params
    this.activeClass = this.dataList.params.start / this.params.limit + 1
    this.pages = Math.ceil(this.dataList.quantityList / this.params.limit)
    if(this.pages == 1){
      this.items = [1]
      this.next = false
    } else if(this.pages == 2){
      this.items = [1, 2]
    }

    this.numList(this.activeClass, true)
    // if(this.pages == this.activeClass){
    //   this.nextList()
    // } else{
    //   this.numList(this.activeClass)
    // }
  },
  watch: {
    dataList: function(){
      this.params = this.dataList.params
      if(this.params.start == '0'){
        this.activeClass = 0
        this.prev = false
        this.next = true
      }
      this.pages = Math.ceil(this.dataList.quantityList / this.params.limit)
      if(this.pages == 1){
        this.items = [1]
        this.next = false
      } else if(this.pages == 2){
        this.items = [1, 2]
      }
    }
  },
  methods: {
    prevList(){
      this.params.start = this.params.start - this.params.limit
      let num = this.items
      let numberFirst = this.items[0]
      if(numberFirst !== 1 && this.activeClass == 1){
        this.items = [numberFirst - 1, numberFirst, numberFirst + 1]
        this.activeClass = 1
        this.next = true
      } else if(numberFirst == 1 && this.pages > 3){
        this.activeClass = 0
        this.prev = false
      } else{
        this.activeClass = 1
        this.next = true
      }
      if(this.pages == 2 || this.pages == 3 && this.params.start == 0){
        this.prev = false
        this.next = true
        this.activeClass = 0
      }
      this.$emit('pagination', {start: this.params.start, load: false})
    },
    numList(number, load){
      this.params.start = number * this.params.limit - this.params.limit
      if(number == 1){
        this.activeClass = 0
        this.params.start = 0
        this.prev = false
        this.next = true
      } else if(number == this.pages){
        this.next = false
        this.prev = true
        this.activeClass = 2
        
      } else{
        this.activeClass = 1
        this.next = true
        this.prev = true
        this.items = [+number - 1, +number, +number + 1]
      }
      if(number == 2 && number == this.pages){
        this.activeClass = 1
        
      }
      if(number == 1 && number == this.pages){
        this.next = false
        
      }
      this.$emit('pagination', {start: this.params.start, load})
    },
    nextList(){
      this.params.start = this.params.start + this.params.limit
      this.prev = true
      let num = this.items
      let numberFirst = this.items[0]
      if(this.activeClass !== 0 && numberFirst + 2 < this.pages){
        this.items = [numberFirst + 1, numberFirst + 2, numberFirst + 3]
      } else if(this.activeClass == 0){
        this.activeClass = 1
      } else if(numberFirst + 2 == this.pages){
        this.activeClass = 2
        this.next = false
      }
      if(this.pages == 2 && this.activeClass == 1){
        this.next = false
      }
      this.$emit('pagination', {start: this.params.start, load: false})
    },
    
  },

  
}
</script>