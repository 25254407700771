<template>
  <div class="block-search">
    <form>
      <input type="text" :placeholder="$store.state.langText.search" 
        v-model="searchInput"
      >
      <a class="form__close" v-if="searchInput != ''" @click.prevent="searchInput = '', search()"></a>
      <button @click.prevent="search" class="icon icon-search"></button>
    </form>
    <a class="icon icon-filters" v-if="filterNone !== 'none'" :class="isActiveFilter ? 'active' : ''" @click.prevent="openFilters"></a>
  </div>
  
</template>


<style >
  .block-search .form__close{
    position: absolute;
    right: 40px;
    top: 19px;
    transform: scale(1.3);
  }
  .block-search form input{
    padding-right: 70px;
  }
</style>


<script>

export default {
  props: ['isSearch', 'isActiveFilter', 'filterNone', 'search2'],
  data: () => ({
    searchInput: '',
  }),


  watch:{
    search2: function(){
      this.searchInput = this.search2
    }
  },
  created() {
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) { p[k] = v })
      return k ? p[k] : p;
    }
    function serialize(obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          // var k = prefix ? prefix + "[" + p + "]" : p,
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
            // k + '=' + v);
        }
      }
      return str.join("&");
    }


    var efe = getSearchParams()

    if(this.isSearch !== false){
      if(this.filterNone == "none"){
        if(efe['search']) {
          this.searchInput = efe['search']
          var search = serialize({search: efe['search']}, 'f')
        } else{
          var search = ''
        }
      } else{
        if(efe['f[search]']) {
          this.searchInput = efe['f[search]']
          var search = serialize({search: efe['f[search]']}, 'f')
        } else{
          var search = ''
        }
      }
    }
    this.$emit('inputchange', {search, load: true})
  },

  methods: {
    openFilters(){
      document.querySelectorAll('.button-back')[0].classList.add("hidden");
      this.$emit('filter');
    },
    search(){
      //decoder
      var app = this
      function serialize(obj, prefix) {
        var str = [],
          p;
        for (p in obj) {
          if (obj.hasOwnProperty(p)) {
            if(app.filterNone == "none"){
              var k = prefix ? prefix + "" + p + "" : p,
                v = obj[p];
            } else{
              var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            }
            str.push((v !== null && typeof v === "object") ?
              serialize(v, k) :
              encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
        return str.join("&");
      }
      var data = {
        search: this.searchInput
      }

      if(app.filterNone == "none"){
        if(this.searchInput == ''){
          var search = ''
        } else{
          var search = serialize(data, '')
        }
      } else{
        if(this.searchInput == ''){
          var search = ''
        } else{
          var search = serialize(data, 'f')
        }
      }
      this.$emit('inputchange', {search})
    }
  },
}
</script>