////////////////////////////////////////////////////////////////
// плагин автоматического обновления
////////////////////////////////////////////////////////////////




import AutoReload from './AutoReload';

const AutoReloadModule = {
    /**
     * установить плагин
     * @param {Object} app экземпляр приложения Vue
     * @param {Object} options настройки
     */
    install(app, options) {
        const module = new AutoReload(options);
        module.init();

        // запоминаем ссылку на модуль плагина, это нужно для демонстрации работы плагина
        app.config.globalProperties.$autoReload = module;
    },
};

export default AutoReloadModule;
