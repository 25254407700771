<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2>{{$store.state.langText.countries}}</h2>
        <Search :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">{{$store.state.langText.nothing_found}}</p>
        <ul class="pop-up__list">
          <li
            v-for="(country, index) in countrys"
            :key="index"
            :data-id="country.id"
            @click.prevent="close(country.name, country.id, country.country_code.toLowerCase())"
          >
            <div class="pop-up__list__row">
              <img v-if="country.country_code" :src="'https://crm-core.soft-pro.com/assets/img/flags/' + country.country_code.toLowerCase() + '.svg'" alt=""> 
              {{country.name}}
            </div>
            <div class="decor-line"></div>
          </li>
        </ul>
        <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
      </div>
    </div>
  </transition>
</template>


<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
  .pop-up__list li img{
    height: 15px;
    margin-right: 10px;
  }

</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"

export default {
  props: ['popup', 'input'],

  data:() => ({
    countrys: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    listNot: false
    
  }),
  async mounted() {
    //base
    const country = await this.$store.dispatch('postFiltersCountry', {params: this.params, data: ''});
    this.countrys = country.data.list;
    this.quantityList = country.data.total_num
  },
  methods: {

    close(name, id, img){
      if(this.input){
        this.$emit('close', {id, name, img, input: this.input})
      } else{
        this.$emit('close', {id, name, img})
      }
    },


    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData
      var arrFilters = await this.$store.dispatch('postFiltersCountry', {params: this.params, data: this.arrFilters})
      this.countrys = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //search
    async searchBtn(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search
  }
  
}
</script>